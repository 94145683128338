import { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import "./Blogs.scss";

function Press(props) {
  const authCtx = props.authCtx;

  const [pressArticles, setPressArticles] = useState([]);
  function getPressArticles() {
    fetch(authCtx.baseUrl + "press_articles")
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setPressArticles(data);
      });
  }
  useEffect(() => {
    getPressArticles();
  }, []);
  return (
    <section id="blog">
      <div className="seperator mt-20"></div>
      <div className="breadcrumb py-2 px-5 sm:px-20 2xl:px-32">
        <Link to="/" className="item">
          Home
        </Link>
        <div className="item">-</div>
        <div className="item current">Press</div>
      </div>
      <div className="seperator mb-10"></div>
      <section className="px-5 sm:px-16 2xl:px-28">
        <h1 className="heading">
          Press Release
          <hr />
        </h1>
        <div className="main_row press_main_row mt-8 md:mt-20">
          <div className="blogs_wrapper">
            {pressArticles.map((article) => (
              <a
                href={article.url}
                key={article.id}
                target="_blank"
                className="blogCard border"
              >
                <img src={article.image} alt="" />
                <div className="body p-5">
                  <h2 className="m-0">{article.title}</h2>
                </div>
              </a>
            ))}
          </div>
        </div>
      </section>
    </section>
  );
}

export default Press;
