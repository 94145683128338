import { Fragment } from "react";
import { Helmet } from "react-helmet";

function Ewaste() {
  return (
    <Fragment>
      <Helmet>
        <title>Coocaa Televisions - E-waste</title>
        {/* <meta name="description" content="" /> */}
        {/* <meta name="keywords" content="" /> */}
      </Helmet>
      <div className="mt-32 px-5 sm:px-20 2xl-px-32">
        <h1 className="heading">E-waste</h1>
        <hr />

        <h2 className="text-2xl font-medium mt-10">E-Waste Recycling Policy</h2>

        <div className="text-gray-800 leading-6">
          <p className="mt-5">
            E-Waste (Management &amp; Handling) Rules, 2011 were notified in
            2011 and had come into effect on 1st May, 2012. SKYWORTH INDIA
            ELECTRONICS PRIVATE LIMITED was one of the Producer who has
            effectively implemented E-Waste Rules and very responsibly managed
            all aspects as per the set rules. We also kept on making regular
            upgrades to comply all perspective of environmental conservation
            through partner association for handling and management of
            electronic and electrical waste, in no ways ending at landfill.
          </p>
          <p className="mt-5">
            Now, as per the new guidelines, MoEF&amp;CC, Government of India in
            supersession of E-Waste (Management and Handling) Rules, 2011 has
            notified the E-Waste (Management) Rules, 2016 vide G.S.R. 338(E)
            dated 23.03.2016 which has now been effective from 1st October'2017.
            These rules are applicable to every producer, consumer or bulk
            consumer, collection centre, dismantler and recycler of e- waste
            involved in the manufacture, sale, purchase and processing of
            electrical and electronic equipment or components specified in
            Schedule - I of the Rules.
          </p>

          <p className="mt-5">
            The categories of electrical and electronic equipment namely
            Televisions are covered under these rules, within Extended Producer
            Responsibility (EPR). Target based approach for implementation of
            EPR has been adopted in the E-Waste (Management) Rules, 2017, which
            stipulate phase wise collection target to producers for the
            collection of e-waste, either in number or weight for respective
            category, which shall be SKYWORTH INDIA ELECTRONICS PRIVATE LIMITED
            to be calculated and notified henceforth by Central Pollution
            Control Board. Also, the new targets are to be calculated on the
            periodic sale as per category under the Life-Cycle fulfilment of
            each category. And, hence a 10% on target on each following year.
          </p>
          <p className="mt-5">
            But with sheer pro-activeness and continued guidance from the
            management, we at SKYWORTH INDIA ELECTRONICS PRIVATE LIMITED within
            no-time, have updated our systems and processes as per the mandate
            of E-Waste (Management) Rules, 2016 and its amendments, which
            includes specific guidelines for extended producer responsibility,
            channelization, collection centres, storage, transportation,
            environmentally sound dismantling and recycling, refurbishment, and
            random sampling of EEE for testing of RoHS parameters.
          </p>

          <h2 className="text-2xl font-medium mt-20">E-Waste Collection</h2>

          <p className="mt-5">
            SKYWORTH INDIA ELECTRONICS PRIVATE LIMITED solely developed an
            internal standard procedure for collection and proper recycling of
            e-waste abiding all aspects of the E-Waste (Management) Rules, 2016
            and its amendments. For collection we have made an Internal Training
            Module to create/spread awareness across all internal and external
            consumers and even to our vendors / partners.
          </p>
          <p className="mt-5">
            We also have collectively developed the PAN-India collection
            mechanism along with our appointed Producer Responsibility
            Organization, Equal Apple Consulting The PRO has been given clear
            mandate to collect e-wastes from respective states as per our
            internal process and channelize it to authorized dismantles and
            recyclers for environmentally sound recycling as required by the
            rules
          </p>

          <p className="mt-5">
            Any of your electronics sold by us on reaching its specified
            end-of-life timeline, as per the Product Catalogue / Product
            Information Docket please drop in an email or call the mentioned.
          </p>

          <table className="mt-10">
            <tr>
              <th>SKYWORTH INDIA ELECTRONICS PRIVATE LIMITED</th>
              <th>Authorised PRO</th>
            </tr>
            <tr>
              <td className="pt-5">
                <p>
                  Customer Contact Number -{" "}
                  <a href="tel:+1800 121 0108">
                    <b>1800 121 0108</b>
                  </a>
                </p>
                <p>
                  E-mail ID:{" "}
                  <a href="mailto:info@skyworth.in">info@skyworth.in</a>
                </p>
                <p>
                  Website - <a href="www.skyworth.in">www.skyworth.in</a>
                </p>
              </td>
              <td className="pt-5 pl-10">
                <p>
                  Toll Free Number -{" "}
                  <a href="tel:+1800 419 82920">1800 419 82920</a>
                </p>
                <p>
                  E-mail ID:{" "}
                  <a href="mailto:info@equalapple.com">info@equalapple.com</a>
                </p>
                <p>
                  Website - <a href="www.equalapple.com">www.equalapple.com</a>
                </p>
              </td>
            </tr>
          </table>

          <h2 className="text-2xl font-medium mt-20"> Collection Points</h2>

          <p className="mt-5">
            SKYWORTH INDIA ELECTRONICS PRIVATE LIMITED has been committed to
            effective collection of all e-waste from PAN-India location and has
            been managing collection of e-waste using its own collection centres
            and the collection centres of its appointed PRO. We have been
            assigning the appointed PRO empanelled by us to collect the
            cumulative load within 180 days from it been deposited by the
            consumer.
          </p>

          <h2 className="text-2xl font-medium mt-20">
            Do&#39;s and Dont&#39;s
          </h2>

          <h3 className="text-lg font-medium mt-5">Do's</h3>
          <ol className="ml-5" style={{ listStyle: "number" }}>
            <li>
              Please check for the life-cycle of your product on the Product
              Information Booklet.
            </li>
            <li>
              Please speak on the mentioned Toll-Free Number for any query about
              your product take-back or recycle
            </li>
            <li>
              Please hand-over e-waste only at the designated collection centre
              of the Brand.
            </li>
            <li>
              Please make sure you earn incentive from the brand on handing-over
              e-waste the correct way.
            </li>
            <li>
              Please accumulate all unused electronic and electrical items only
              to be handed-over to the Brand or Registered Recycler.
            </li>
            <li>
              Please acknowledge buy-back and exchange program from the Brand.
            </li>
            <li>
              Please drop your scrap electronic parts, handed-back to you after
              replacement with the new part, at the e-waste drop box in the
              service centre.
            </li>
            <li>
              Please register that as an individual we all have Individual
              Responsibility towards the conservation of our environment.
            </li>
          </ol>

          <h3 className="text-lg font-medium mt-5">Dont's</h3>
          <ol className="ml-5" style={{ listStyle: "number" }}>
            <li>
              Please do not throw any electronics (including batteries) into
              garbage bin. It must be thrown only on designated bins with symbol
              <img
                style={{ height: "30px",display:'inline' }}
                src="static/images/bin.png"
                alt=""
              />
            </li>

            <li>
              Please do not sell e-waste to scrap dealers or unauthorised agents
              for little/some cash.
            </li>
            <li>
              Please do not dispose of e-waste in landfill or in pits as an
              ill-treasure for your next generation
            </li>
            <li>
              Please do not cut-open parts or components without brand
              representative's supervision
            </li>
          </ol>

          <h2 className="text-2xl font-medium mt-20">Health Effects</h2>
          <p className="mt-5">
            When we throw out our electronics, they land up in Landfill causing
            the possibility of toxic metal leaching into the groundwater. Toxic
            metals in e-waste leach into our supply of resources, threatening
            their integrity. When e-waste is openly heated which is a
            rudimentary process by the un-registered user or scrap dealers,
            toxic chemicals are released into the air, damaging the atmosphere
            and posing a serious threat to health and existing life.
          </p>
          <p>
            E-Waste management is a critical consideration for future
            generations as proper e-waste recycling is becoming hard to find,
            with very less efficient recyclers in the business.
          </p>
          <p>
            It is crucial to know effects of e-waste on the environment, and
            make a collective effort to build awareness and systems to curb all
            its ill positioning for our existing and future generation.
          </p>
          <p>
            To know more please call on Toll Free No.{" "}
            <a href="tel:+18001210108">
              <b>18001210108</b>
            </a>{" "}
            for more details
          </p>

          <h2 className="text-2xl font-medium mt-20">Consequences</h2>
          <p className="mt-5">
            Improper handling, accidental breakage damage and improper recycling
            of the end of life product has the following consequences: If
            batteries are not properly disposed of, it can cause harm to human
            health or the environment. Placing of batteries or devices on or in
            heating devices, such as microwave ovens, stoves, or radiators and
            improper disposal of batteries may lead to explosion. If the battery
            terminals are in contact with metal objects, it may cause a fire.
          </p>

          <h2 className="text-2xl font-medium mt-20 mb-10">FAQs</h2>
          <b>1. What is E-waste?</b>
          <p className="mb-5">
            Any electronic and electrical product or equipment which has been
            out of use due to a technical flaw, upgradation of technology or
            unforeseen damage, can be termed as e-waste.
          </p>
          <b>2. What is EPR</b>
          <p className="mb-5">
            This has been implemented in India for the first time, as per the
            new E-Waste (Management) Rules 2016, where Extended Producer
            Responsibility has been bestowed upon the Manufactures, basically
            companies producing or marketing electronics and electrical items
            under the respective brand name. Hence, the responsibility is on
            them to ensure the take-back from the consumer, after it’s end of
            life as described in the Rules to ensure proper management and
            disposition with a note to protect the environment.
          </p>
          <b>
            3. How am I being a consumer coved under the new E-Waste Management
            Rules 2016
          </b>
          <p className="mb-5">
            Each stakeholder has a separate responsibility as per the E-Waste
            (Management) Rules 2016, the manufacture, the bulk consumer /
            Consumer, recycler etc. Now, that Bulk Consumers are enterprise or
            house of business which uses electronics in bulk for their course of
            business and hence is also under the rules to ensure compliance,
            till date for individual consumers there is no such compliance but
            as a key stakeholder, an individual should support the entire
            eco-system to ensure protection of the environment. And, is always
            recommended to encourage group, children, family, friends etc not to
            dispose e-waste in landfill or hand-over to scrap dealers or
            unauthorised vendors.
          </p>
          <b>4. Why is it important to handle e-waste correctly</b>
          <p className="mb-5">
            E-Waste can pose serious health concerns if not handled properly,
            metals used in an electronic or electrical product contains
            hazardous elements like mercury, lead, cobalt etc. If these
            materials come in contact with your health, in any form through air
            or water, can be life threatening. And, it is mandatory for all of
            us to hand-over or dispose e-waste through authorised recyclers or
            management companies only.
          </p>
          <b>5. Where are the collection centers located to drop e-waste</b>
          <p className="mb-5">
            We have a detailed list of locations mentioned under the Collection
            Center tab as you can refer on our website, E-Waste Management &gt;
            Collection Center. For our appointed PRO Collection Centre please
            refer their website www.equalapple.com /Collection Centres
          </p>
          <b>6. What is the incentive for handling over e-waste</b>
          <p>
            BIIPL has an excellent and one of the best incentive program in the
            industry. If you connect to deposit your e-waste with us through the
            mentioned contact details, you will be rest assured to receive
            freebies, vouchers, and hopefully privileged discount on brand
            products. Update by Producer
          </p>
          <p>Terms &amp; Conditions Apply:</p>
          <ul>
            <li>Hazardous Waste won't be collected</li>
            <li>Only E-Waste to be acknowledged</li>
            <li>Product should be radiation free</li>
            <li>
              Product shouldn't pose any hazard to handler or collector etc
            </li>
          </ul>

          <h2 className="text-2xl font-medium mt-20 mb-5">RoHS Compliance</h2>
          <p>
            RoHS stands for Reduction in Hazardous Substance, which is a mandate
            by the regulatory enforcement, which will have pre-requisite for the
            Producer or Manufacturer of the Brand be strictly complying. This is
            purely to structure the usage of hazardous elements in any
            electronic or electrical product, basically to eliminate any threat
            to health and life using them. The term is purely used to understand
            the effect of components in the product on the environment. The
            technical documents (supplier declaration- description of the
            product, document for materials, parts, and/or sub-assemblies and
            analytical test result) as an evidence that the reduction of
            hazardous substance (RoHS) provisions are complied by the product
            based on standard EN 50581 of EU.
          </p>
        </div>
      </div>
    </Fragment>
  );
}

export default Ewaste;
