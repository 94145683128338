import "./Navbar.scss";
import { gsap, Sine } from "gsap/all";
import { Fragment, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

function Navbar(props) {
  const authCtx = props.authCtx;
  const [categories, setCategories] = useState([]);
  const [activeCategoryIndex, setActiveCategoryIndex] = useState(0);
  const [activeSubCategoryIndex, setActiveSubCategoryIndex] = useState(0);
  const header = useRef();
  const dropdown = useRef();
  const b2bDialog = useRef();
  const selector = gsap.utils.selector(dropdown);
  const navigate = useNavigate();
  const mq = window.matchMedia("(max-width: 768px)");
  const IS_SMALL_SCREEN = mq.matches;

  function openDropdwon(index) {
    setActiveCategoryIndex(index);
    gsap
      .timeline({ defaults: { ease: Sine } })
      .to(header.current, {
        background: "rgba(255, 255, 255, 0.8)",
        duration: 0.2,
      })
      .set(
        dropdown.current,
        {
          display: "block",
          opacity: 1,
        },
        0
      )
      // .from(
      //   dropdown.current,
      //   {
      //     height: 0,
      //     duration: 0.2,
      //   },
      //   0
      // )
      .fromTo(
        selector(".dropdown ul, .dropdown .grid"),
        {
          opacity: 0,
          y: 20,
          scale: 0.99,
        },
        {
          opacity: 1,
          y: 0,
          scale: 1,
        },
        0
      );
  }
  function closeDropdwon() {
    gsap
      .timeline({ defaults: { ease: Sine } })
      .to(dropdown.current, {
        opacity: 0,
        duration: 0.2,
      })
      .to(
        header.current,
        {
          background: "rgba(255, 255, 255, 0.5)",
        },
        0
      )
      .to(
        dropdown.current,
        {
          opacity: 0,
          duration: 0.2,
        },
        0
      )
      .set(
        dropdown.current,
        {
          display: "none",
        },
        0.1
      );
  }
  function openMenu() {
    gsap.set(".nav_links", { display: "flex" });
    gsap.from(".nav_links a,.nav_links button", {
      x: 200,
      stagger: 0.03,
      duration: 0.5,
      ease: "expo",
    });
  }
  function closeMenu() {
    closeDropdwon();
    gsap.set(".nav_links", { display: "none" });
  }

  const goToProfile = () => {
    navigate("/profile");
  };

  const get_categories = async () => {
    await fetch(authCtx.baseUrl + "categories")
      .then((res) => res.json())
      .then((data) => {
        // console.log(data);
        setCategories(data);
      });
  };

  const checkAndClose = () => {
    IS_SMALL_SCREEN ? closeMenu() : closeDropdwon();
  };

  const b2bBtnClickHandler = () => {
    console.log(b2bDialog.current);
    b2bDialog.current.showModal();
  };

  const form = useRef();
  const [querySent, setQuerySent] = useState(false);

  async function sendQuery(e) {
    e.preventDefault();
    let formData = new FormData(form.current);
    fetch(authCtx.baseUrl + "b2bquery/", {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        form.current.reset();
        setQuerySent(true);
      });
  }

  useEffect(() => {
    get_categories();
  }, []);
  return (
    <Fragment>
      <header>
        <nav ref={header} className="sm:px-5 py-5">
          <Link to="/" className="logo">
            <svg
              width="170"
              height="28"
              viewBox="0 0 170 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_66_13)">
                <path
                  d="M19.0321 20.846C17.9474 21.6361 16.6901 22.1587 15.3633 22.3709C14.0366 22.5832 12.6783 22.479 11.3998 22.0669C10.1213 21.6548 8.95912 20.9466 8.00862 20.0004C7.05813 19.0541 6.3464 17.8969 5.93187 16.6235C5.51733 15.3502 5.41179 13.9971 5.6239 12.6752C5.83602 11.3534 6.35975 10.1005 7.15212 9.01933C7.94448 7.93819 8.98289 7.05964 10.1821 6.45577C11.3814 5.8519 12.7073 5.53992 14.051 5.54545C15.8408 5.54018 17.5858 6.10368 19.0321 7.15405C19.6311 7.55717 20.364 7.71251 21.0759 7.58725C21.7878 7.46199 22.4229 7.06594 22.8471 6.48278C23.2713 5.89962 23.4513 5.17502 23.3491 4.46209C23.2469 3.74916 22.8704 3.10374 22.2993 2.66219C20.504 1.36517 18.426 0.509781 16.2353 0.165929C14.0446 -0.177923 11.8033 -0.000475096 9.69457 0.683769C7.58586 1.36801 5.66961 2.53963 4.10244 4.10287C2.53526 5.66611 1.36164 7.57659 0.677506 9.67817C-0.0066319 11.7797 -0.181871 14.0128 0.16611 16.1947C0.514092 18.3767 1.37542 20.4457 2.67969 22.2325C3.98396 24.0194 5.69416 25.4734 7.6705 26.4758C9.64684 27.4782 11.8332 28.0005 14.051 28C17.0166 28.0071 19.9073 27.073 22.3041 25.3331C22.8703 24.8895 23.2419 24.2446 23.3411 23.5338C23.4402 22.823 23.2591 22.1015 22.8357 21.5209C22.4123 20.9403 21.7796 20.5457 21.0704 20.4198C20.3612 20.294 19.6307 20.4467 19.0321 20.846Z"
                  fill="#EB6039"
                />
                <path
                  d="M104.877 20.846C103.793 21.6361 102.535 22.1587 101.209 22.3709C99.8818 22.5832 98.5234 22.479 97.2449 22.0669C95.9665 21.6548 94.8043 20.9466 93.8538 20.0004C92.9033 19.0541 92.1916 17.8969 91.777 16.6235C91.3625 15.3502 91.2569 13.9971 91.4691 12.6752C91.6812 11.3534 92.2049 10.1005 92.9973 9.01933C93.7896 7.93819 94.8281 7.05964 96.0273 6.45577C97.2265 5.8519 98.5524 5.53992 99.8961 5.54544C101.685 5.53954 103.428 6.10315 104.873 7.15405C105.168 7.3722 105.505 7.52979 105.862 7.61772C106.219 7.70564 106.59 7.72216 106.954 7.66632C107.318 7.61047 107.667 7.48337 107.981 7.29236C108.295 7.10135 108.568 6.85021 108.785 6.55346C109.001 6.25671 109.156 5.92023 109.241 5.56346C109.325 5.20669 109.338 4.8367 109.279 4.47488C109.219 4.11306 109.089 3.76657 108.894 3.45544C108.699 3.14431 108.444 2.8747 108.144 2.66219C106.349 1.36517 104.271 0.509781 102.08 0.165929C99.8897 -0.177923 97.6484 -0.000475097 95.5397 0.683769C93.431 1.36801 91.5148 2.53963 89.9476 4.10287C88.3804 5.6661 87.2068 7.57659 86.5227 9.67816C85.8385 11.7797 85.6633 14.0128 86.0113 16.1947C86.3592 18.3767 87.2206 20.4457 88.5248 22.2325C89.8291 24.0194 91.5393 25.4734 93.5157 26.4758C95.492 27.4782 97.6784 28.0005 99.8961 28C102.86 28.0081 105.75 27.0738 108.144 25.3331C108.742 24.9015 109.143 24.2512 109.259 23.5252C109.375 22.7993 109.196 22.0572 108.763 21.4621C108.33 20.8671 107.677 20.4679 106.948 20.3523C106.22 20.2368 105.475 20.4143 104.877 20.846Z"
                  fill="#EB6039"
                />
                <path
                  d="M155.949 1.92876e-06C153.718 -0.00116466 151.518 0.526886 149.532 1.54048C147.547 2.55407 145.831 4.02408 144.529 5.82893C143.227 7.63378 142.374 9.72159 142.042 11.9197C141.71 14.1178 141.909 16.3631 142.62 18.4697C143.332 20.5764 144.537 22.484 146.136 24.0346C147.735 25.5853 149.681 26.7346 151.814 27.3873C153.947 28.0401 156.205 28.1776 158.402 27.7885C160.599 27.3994 162.671 26.4948 164.448 25.1497V25.2202C164.432 25.594 164.493 25.9669 164.626 26.3168C164.759 26.6666 164.961 26.986 165.221 27.2559C165.481 27.5258 165.793 27.7405 166.138 27.8872C166.484 28.0339 166.855 28.1095 167.231 28.1095C167.606 28.1095 167.978 28.0339 168.323 27.8872C168.669 27.7405 168.981 27.5258 169.241 27.2559C169.501 26.986 169.703 26.6666 169.836 26.3168C169.969 25.9669 170.03 25.594 170.014 25.2202V13.9976C170.01 10.2839 168.527 6.72362 165.89 4.09896C163.252 1.4743 159.677 4.5636e-08 155.949 1.92876e-06ZM155.949 22.4546C154.268 22.4546 152.625 21.958 151.227 21.0277C149.83 20.0974 148.741 18.7752 148.097 17.2282C147.454 15.6811 147.286 13.9789 147.614 12.3365C147.942 10.6942 148.751 9.18569 149.94 8.00165C151.128 6.81761 152.642 6.01128 154.291 5.6846C155.94 5.35793 157.648 5.52559 159.201 6.16638C160.754 6.80718 162.082 7.89233 163.015 9.28461C163.949 10.6769 164.448 12.3138 164.448 13.9882C164.448 16.2336 163.552 18.3871 161.958 19.9748C160.365 21.5626 158.203 22.4546 155.949 22.4546Z"
                  fill="#EB6039"
                />
                <path
                  d="M124.754 1.92876e-06C122.523 -0.00116466 120.324 0.526886 118.338 1.54048C116.352 2.55407 114.637 4.02408 113.334 5.82893C112.032 7.63378 111.18 9.72159 110.848 11.9197C110.516 14.1178 110.714 16.3631 111.426 18.4697C112.138 20.5764 113.343 22.484 114.942 24.0346C116.54 25.5853 118.487 26.7346 120.62 27.3873C122.752 28.0401 125.011 28.1776 127.208 27.7885C129.404 27.3994 131.477 26.4948 133.253 25.1497V25.2202C133.238 25.594 133.298 25.9669 133.431 26.3168C133.564 26.6666 133.767 26.986 134.026 27.2559C134.286 27.5258 134.599 27.7405 134.944 27.8872C135.289 28.0339 135.661 28.1095 136.036 28.1095C136.412 28.1095 136.783 28.0339 137.129 27.8872C137.474 27.7405 137.786 27.5258 138.046 27.2559C138.306 26.986 138.509 26.6666 138.642 26.3168C138.775 25.9669 138.835 25.594 138.82 25.2202V13.9976C138.816 10.2839 137.332 6.72362 134.695 4.09896C132.058 1.4743 128.482 4.5636e-08 124.754 1.92876e-06ZM124.754 22.4546C123.074 22.4546 121.431 21.958 120.033 21.0277C118.635 20.0974 117.546 18.7752 116.903 17.2282C116.26 15.6811 116.091 13.9789 116.419 12.3365C116.747 10.6942 117.557 9.18569 118.745 8.00165C119.934 6.81761 121.448 6.01128 123.097 5.6846C124.745 5.35793 126.454 5.52559 128.007 6.16638C129.56 6.80718 130.887 7.89233 131.821 9.28461C132.755 10.6769 133.253 12.3138 133.253 13.9882C133.253 16.2336 132.358 18.3871 130.764 19.9748C129.17 21.5626 127.008 22.4546 124.754 22.4546Z"
                  fill="#EB6039"
                />
                <path
                  d="M52.6817 13.9976C52.6827 16.7673 51.8591 19.4749 50.3152 21.7782C48.7713 24.0815 46.5765 25.877 44.0082 26.9375C41.4399 27.998 38.6136 28.276 35.8868 27.7362C33.1599 27.1965 30.655 25.8632 28.6888 23.9052C26.7226 21.9471 25.3835 19.4521 24.8407 16.7358C24.298 14.0195 24.5761 11.2038 25.6398 8.64497C26.7035 6.0861 28.5051 3.89896 30.8166 2.36015C33.1282 0.821343 35.8459 1.56254e-07 38.626 0C42.353 -2.09468e-07 45.9274 1.4746 48.5633 4.09953C51.1991 6.72446 52.6805 10.2848 52.6817 13.9976ZM38.626 5.60188C36.958 5.60188 35.3275 6.09468 33.9407 7.01794C32.5539 7.94121 31.4731 9.25346 30.835 10.7887C30.1969 12.324 30.0302 14.0133 30.3559 15.6429C30.6817 17.2726 31.4853 18.7694 32.665 19.944C33.8448 21.1187 35.3478 21.9184 36.9838 22.242C38.6199 22.5656 40.3155 22.3986 41.8563 21.762C43.397 21.1255 44.7137 20.048 45.6397 18.666C46.5657 17.2839 47.0595 15.6593 47.0585 13.9976C47.0573 11.7705 46.1683 9.63504 44.587 8.06066C43.0058 6.48628 40.8616 5.60188 38.626 5.60188Z"
                  fill="#EB6039"
                />
                <path
                  d="M83.3144 13.9976C83.3154 16.7675 82.4917 19.4753 80.9476 21.7787C79.4035 24.0821 77.2083 25.8775 74.6398 26.9379C72.0712 27.9983 69.2447 28.276 66.5177 27.7359C63.7908 27.1957 61.2858 25.8621 59.3198 23.9035C57.3538 21.945 56.015 19.4495 55.4729 16.7329C54.9307 14.0163 55.2094 11.2005 56.2738 8.64171C57.3383 6.08292 59.1406 3.8961 61.4527 2.35786C63.7649 0.819622 66.4831 -0.000929455 69.2634 7.90075e-07C72.9896 0.00124748 76.5628 1.4764 79.1976 4.10119C81.8324 6.72599 83.3132 10.2856 83.3144 13.9976ZM69.2634 5.60188C67.5952 5.60095 65.9643 6.09296 64.5768 7.01566C63.1894 7.93835 62.1078 9.25028 61.469 10.7855C60.8302 12.3206 60.6628 14.0101 60.988 15.64C61.3132 17.27 62.1165 18.7673 63.2961 19.9424C64.4756 21.1175 65.9786 21.9177 67.6148 22.2416C69.251 22.5656 70.9468 22.3989 72.4879 21.7625C74.0289 21.1261 75.3458 20.0486 76.272 18.6665C77.1982 17.2843 77.6921 15.6595 77.6912 13.9976C77.6912 11.771 76.8033 9.63546 75.2228 8.06094C73.6422 6.48643 71.4986 5.60188 69.2634 5.60188Z"
                  fill="#EB6039"
                />
              </g>
              <defs>
                <clipPath id="clip0_66_13">
                  <rect width="170" height="28" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </Link>
          <div className="nav_links">
            <div className="close" onClick={checkAndClose}>
              <svg
                width="24"
                height="24"
                xmlns="http://www.w3.org/2000/svg"
                fillRule="evenodd"
                clipRule="evenodd"
              >
                <path d="M12 11.293l10.293-10.293.707.707-10.293 10.293 10.293 10.293-.707.707-10.293-10.293-10.293 10.293-.707-.707 10.293-10.293-10.293-10.293.707-.707 10.293 10.293z" />
              </svg>
            </div>
            <Link to="/about" onClick={checkAndClose}>
              About Us
            </Link>
            <Link to="/products/new-launches" onClick={checkAndClose}>
              New launches
            </Link>
            {categories.map((category, i) => (
              <Link
                to={"/products/" + category.slug}
                onMouseEnter={() => openDropdwon(i)}
                key={i}
              >
                {category.name}
              </Link>
            ))}
            <Link to="/blogs" onClick={checkAndClose}>
              Blogs
            </Link>
            <Link to="/e-waste" onClick={checkAndClose}>
              E-Waste
            </Link>
            {/* <Link to="/service-centers" onClick={checkAndClose}>
            Service Centers
          </Link> */}
            <Link to="/support" onClick={checkAndClose}>
              Support
            </Link>
            <div className="flex justify-end" onClick={checkAndClose}>
              <button onClick={b2bBtnClickHandler} className="b2b_btn">
                B2B Enquiries
              </button>
            </div>
          </div>
          <div className="nav_icons">
            {/* <div className="icon">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19.7263 17.2913L15.8322 13.3971C15.6564 13.2214 15.4182 13.1237 15.1682 13.1237H14.5315C15.6096 11.7449 16.2501 10.0107 16.2501 8.12421C16.2501 3.63636 12.6138 0 8.12592 0C3.63807 0 0.00170898 3.63636 0.00170898 8.12421C0.00170898 12.612 3.63807 16.2484 8.12592 16.2484C10.0125 16.2484 11.7467 15.6079 13.1254 14.5298V15.1665C13.1254 15.4165 13.2231 15.6547 13.3988 15.8305L17.293 19.7246C17.6601 20.0918 18.2538 20.0918 18.6171 19.7246L19.7224 18.6193C20.0896 18.2521 20.0896 17.6584 19.7263 17.2913ZM8.12592 13.1237C5.36447 13.1237 3.1264 10.8896 3.1264 8.12421C3.1264 5.36276 5.36056 3.12469 8.12592 3.12469C10.8874 3.12469 13.1254 5.35885 13.1254 8.12421C13.1254 10.8857 10.8913 13.1237 8.12592 13.1237Z"
                fill="#EB6039"
              />
            </svg>
          </div> */}
            <button className="b2b_btn" onClick={b2bBtnClickHandler}>
              B2B Enquiries
            </button>
            <div
              className="icon"
              onClick={authCtx.isLoggedIn ? goToProfile : authCtx.showLogin}
            >
              <svg
                width="18"
                height="20"
                viewBox="0 0 18 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9 10C11.7617 10 14 7.76172 14 5C14 2.23828 11.7617 0 9 0C6.23828 0 4 2.23828 4 5C4 7.76172 6.23828 10 9 10ZM12.5 11.25H11.8477C10.9805 11.6484 10.0156 11.875 9 11.875C7.98438 11.875 7.02344 11.6484 6.15234 11.25H5.5C2.60156 11.25 0.25 13.6016 0.25 16.5V18.125C0.25 19.1602 1.08984 20 2.125 20H15.875C16.9102 20 17.75 19.1602 17.75 18.125V16.5C17.75 13.6016 15.3984 11.25 12.5 11.25Z"
                  fill="#313131"
                />
              </svg>
            </div>
            <Link to="/cart" className="icon">
              <svg
                width="18"
                height="20"
                viewBox="0 0 18 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14 6.25V5C14 2.24297 11.757 0 9 0C6.24297 0 4 2.24297 4 5V6.25H0.25V16.875C0.25 18.6009 1.6491 20 3.375 20H14.625C16.3509 20 17.75 18.6009 17.75 16.875V6.25H14ZM6.5 5C6.5 3.62148 7.62148 2.5 9 2.5C10.3785 2.5 11.5 3.62148 11.5 5V6.25H6.5V5ZM12.75 9.6875C12.2322 9.6875 11.8125 9.26777 11.8125 8.75C11.8125 8.23223 12.2322 7.8125 12.75 7.8125C13.2678 7.8125 13.6875 8.23223 13.6875 8.75C13.6875 9.26777 13.2678 9.6875 12.75 9.6875ZM5.25 9.6875C4.73223 9.6875 4.3125 9.26777 4.3125 8.75C4.3125 8.23223 4.73223 7.8125 5.25 7.8125C5.76777 7.8125 6.1875 8.23223 6.1875 8.75C6.1875 9.26777 5.76777 9.6875 5.25 9.6875Z"
                  fill="#313131"
                />
              </svg>
            </Link>
          </div>
          <div className="menu_open_btn mr-5 ml-3" onClick={openMenu}>
            <svg
              width="24"
              height="24"
              xmlns="http://www.w3.org/2000/svg"
              fillRule="evenodd"
              clipRule="evenodd"
            >
              <path
                d="M24 18v1h-24v-1h24zm0-6v1h-24v-1h24zm0-6v1h-24v-1h24z"
                fill="#1040e2"
              />
              <path d="M24 19h-24v-1h24v1zm0-6h-24v-1h24v1zm0-6h-24v-1h24v1z" />
            </svg>
          </div>
        </nav>
        <div ref={dropdown} className="dropdown_wrapper">
          {/* <div className="close" onClick={closeDropdwon}>
          <svg
            width="24"
            height="24"
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            clipRule="evenodd"
          >
            <path d="M12 11.293l10.293-10.293.707.707-10.293 10.293 10.293 10.293-.707.707-10.293-10.293-10.293 10.293-.707-.707 10.293-10.293-10.293-10.293.707-.707 10.293 10.293z" />
          </svg>
        </div> */}
          <div onMouseLeave={closeDropdwon} className="dropdown sm:pl-32">
            <ul>
              {categories[activeCategoryIndex]?.sub_categories?.map(
                (sub_cat, i) => (
                  <li
                    className={activeSubCategoryIndex == i ? "active" : ""}
                    onClick={() => {
                      setActiveSubCategoryIndex(i);
                    }}
                    key={i}
                  >
                    {sub_cat.name}
                  </li>
                )
              )}
            </ul>
            {categories[activeCategoryIndex]?.sub_categories.length == 0 ? (
              <div className="coming_soon">
                <center>Coming soon</center>
              </div>
            ) : null}
            <div className="grid">
              {categories[activeCategoryIndex]?.sub_categories[
                activeSubCategoryIndex
              ]?.product_set.map((product) => (
                <Link
                  to={"/" + product.slug}
                  onClick={checkAndClose}
                  className="item"
                  key={product.sku}
                >
                  <img src={product.image} alt="" />
                  <h1>{product.name}</h1>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </header>

      <dialog ref={b2bDialog}>
        {querySent ? (
          <Fragment>
            <h1 className="mt-10 text-xl">
              We've received your message. We'll get get back to you soon.
            </h1>
            <button className="p_btn mt-5" onClick={() => {setQuerySent(false);b2bDialog.current.close()}}>
              Done
            </button>
          </Fragment>
        ) : (
          <form
            ref={form}
            onSubmit={sendQuery}
            id="submit_b2b_form"
            method="post"
          >
            <section>
              <h2>B2B Enquiry</h2>
              <div className="response_msg"></div>
              <div className="input_row">
                <input
                  required
                  type="text"
                  placeholder="Enter your Name"
                  maxlength="100"
                  name="name"
                />
                <input
                  required
                  type="tel"
                  pattern="^[+]?([(][0-9]{1,3}[)][-]?)?([0-9][-]?){3,16}[0-9]$"
                  maxlength="12"
                  placeholder="Enter your Mobile Number"
                  name="phone"
                />
              </div>
              <div className="input_row">
                <input
                  required
                  type="email"
                  pattern="^.+@.+\.[a-zA-Z]{2,63}$"
                  maxlength="250"
                  placeholder="Enter your Email"
                  name="email"
                />
                <input required type="text" name="location" placeholder="Enter your Location" />
              </div>
              <div className="input_row">
                <textarea
                  required
                  placeholder="Enter your comments"
                  className="span_2"
                  name="message"
                ></textarea>
              </div>
              <menu>
                <button className="p_btn" type="submit">
                  Submit
                </button>
              </menu>
            </section>
            <button
              className="close_btn"
              onClick={() => b2bDialog.current.close()}
              type="reset"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path d="M23 20.168l-8.185-8.187 8.185-8.174-2.832-2.807-8.182 8.179-8.176-8.179-2.81 2.81 8.186 8.196-8.186 8.184 2.81 2.81 8.203-8.192 8.18 8.192z" />
              </svg>
            </button>
          </form>
        )}
      </dialog>
    </Fragment>
  );
}

export default Navbar;
