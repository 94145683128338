import { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthContext from "./../../auth-context";
import "./Profile.scss";

function Profile() {
  const authCtx = useContext(AuthContext);
  const form = useRef();
  const [profile, setProfile] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const navigate = useNavigate();
  const getProfile = () => {
    fetch(authCtx.baseUrl + "customer/" + authCtx.userId, {
      headers: {
        Authorization: `Token ${authCtx.token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setProfile(data);
      });
  };
  const saveProfile = (e) => {
    e.preventDefault();
    const fd = new FormData(form.current);
    fetch(`${authCtx.baseUrl}customer/${authCtx.userId}/`, {
      method: "PATCH",
      body: fd,
      headers: {
        Authorization: `Token ${authCtx.token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setIsEditing(false);
      });
  };
  const logout = () => {
    fetch(authCtx.baseUrl + "logout/", {
      method: "POST",
      headers: {
        Authorization: `Token ${authCtx.token}`,
      },
    }).then((res) => {
      authCtx.logout();
      navigate("/");
    });
  };
  useEffect(() => {
    getProfile();
  }, []);
  return (
    <div id="profile" className="px-5 sm:px-16 2xl:px-28 py-20 md:py-32">
      <div className="account_header">
        <h1 className="heading">Account</h1>
        <button onClick={logout}>Logout</button>
      </div>
      <hr />
      <div className="cards_wrapper">
        <div className="profile_card p-5 xl:px-10 xl:py-6">
          {isEditing ? (
            <div className="edit_btn">
              <svg
                className="close"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                onClick={() => {
                  setIsEditing(false);
                }}
              >
                <path d="M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z" />
              </svg>
            </div>
          ) : (
            <div className="edit_btn">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="43.856"
                height="43.856"
                viewBox="0 0 43.856 43.856"
                onClick={() => {
                  setIsEditing(true);
                }}
              >
                <g id="Icon_feather-edit" transform="translate(-1.5 -1.318)">
                  <path
                    id="Path_606"
                    data-name="Path 606"
                    d="M21.274,6H7.061A4.061,4.061,0,0,0,3,10.061V38.488a4.061,4.061,0,0,0,4.061,4.061H35.488a4.061,4.061,0,0,0,4.061-4.061V24.274"
                    transform="translate(0 1.125)"
                    fill="none"
                    stroke="#444b6d"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="3"
                  />
                  <path
                    id="Path_607"
                    d="M33.32,4.08a4.307,4.307,0,0,1,6.091,6.091L20.122,29.461,12,31.491l2.03-8.122Z"
                    transform="translate(3.183 0)"
                    fill="none"
                    stroke="#de6a22"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="3"
                  />
                </g>
              </svg>
            </div>
          )}
          <form onSubmit={saveProfile} ref={form}>
            {!isEditing ? (
              <h1>{profile.name}</h1>
            ) : (
              <input type="text" name="name" defaultValue={profile.name} />
            )}
            <table>
              <tbody>
                <tr>
                  <td>Email Id:</td>
                  <td>
                    {!isEditing ? (
                      profile.email
                    ) : (
                      <input
                        type="email"
                        name="email"
                        defaultValue={profile.email}
                      />
                    )}
                  </td>
                </tr>

                <tr>
                  <td>Mobile no:</td>
                  <td>{profile.phone}</td>
                </tr>
              </tbody>
            </table>
            {isEditing ? (
              <button type="submit" className="p_btn">
                Save
              </button>
            ) : (
              <Link to="/change-password">Change Password?</Link>
            )}
          </form>
        </div>
        <div className="profile_card icon_card p-5 sm:ml-10">
          <svg
            width="18"
            height="20"
            viewBox="0 0 18 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14 6.25V5C14 2.24297 11.757 0 9 0C6.24297 0 4 2.24297 4 5V6.25H0.25V16.875C0.25 18.6009 1.6491 20 3.375 20H14.625C16.3509 20 17.75 18.6009 17.75 16.875V6.25H14ZM6.5 5C6.5 3.62148 7.62148 2.5 9 2.5C10.3785 2.5 11.5 3.62148 11.5 5V6.25H6.5V5ZM12.75 9.6875C12.2322 9.6875 11.8125 9.26777 11.8125 8.75C11.8125 8.23223 12.2322 7.8125 12.75 7.8125C13.2678 7.8125 13.6875 8.23223 13.6875 8.75C13.6875 9.26777 13.2678 9.6875 12.75 9.6875ZM5.25 9.6875C4.73223 9.6875 4.3125 9.26777 4.3125 8.75C4.3125 8.23223 4.73223 7.8125 5.25 7.8125C5.76777 7.8125 6.1875 8.23223 6.1875 8.75C6.1875 9.26777 5.76777 9.6875 5.25 9.6875Z"
              fill="#313131"
            />
          </svg>
          <p>Your Orders</p>
        </div>
        <div className="profile_card icon_card p-5 sm:ml-10">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M0 2v20h4c0-1.104.896-2 2-2s2 .896 2 2h8c0-1.104.896-2 2-2s2 .896 2 2h4v-20h-24zm13 14h-10v-.417c-.004-1.112.044-1.747 1.324-2.043 1.403-.324 2.787-.613 2.122-1.841-1.973-3.637-.563-5.699 1.554-5.699 2.077 0 3.521 1.985 1.556 5.699-.647 1.22.688 1.51 2.121 1.841 1.284.297 1.328.936 1.323 2.057v.403zm8 0h-6v-2h6v2zm0-4h-6v-2h6v2zm0-4h-6v-2h6v2z" />
          </svg>
          <p>Your Address</p>
        </div>
      </div>
    </div>
  );
}

export default Profile;
