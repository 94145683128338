import { Fragment } from "react";
import { Helmet } from "react-helmet";

function About() {
  return (
    <Fragment>
      <Helmet>
        <title>About Us - Coocaa Smart Televisions </title>
        <meta
          name="description"
          content="Coocaa is a registered trademark of the market-leading TV manufacturer SKYWORTH. Since 2006, Coocaa brings fun to the home - with TVs that inspire around the world."
        />
      </Helmet>
      <div className="mt-32 px-5 sm:px-20 2xl-px-32">
        <h1 className="heading">ABOUT US</h1>
        <hr className="mb-10" />
        <div className="text-gray-600 leading-6">
          <div className="md:flex">
            <div className="basis-1/2 md:pr-10">
              <p>
                Coocaa is a registered trademark of the market-leading TV
                manufacturer SKYWORTH. Since 2006, coocaa brings fun to the home
                - with TVs that inspire around the world. In doing so, coocaa
                not only promotes the innovation and development of advanced
                technologies, but also strives for product quality that will
                bring a smile to customers' faces.
              </p>
              <h3 className="text-xl font-medium mt-10">
                Coocaa is an internet Lifestyle Brand
              </h3>
              <p className="mt-5 ">
                Coocaa is committed to creating an Internet lifestyle for the
                young generation. With a mission to promote intelligent
                lifestyles through its innovative technology, Coocaa is
                committed to developing smart and trendy products that feature
                sleek and fun designs to meet the needs of the younger
                generation. We have set foot on more than 40 countries, built 9
                manufacturing bases and 6 R&D centers globally. We have more
                than 30 years of core technology and development strength.
              </p>
            </div>
            <div className="basis-1/2 my-10 md:my-0">
              <img src="static/images/about1.jpg" alt="" />
            </div>
          </div>
          <h4 className="text-md">Coocaa TV Global Business</h4>
          <p className="mt-5">
            Coocaa mainly focuses on the overseas online markets, and has set up
            cross-border e-commerce platforms in India, Europe, Russia and
            Southeast Asia, as well as localized e-commerce platforms. At
            present, Coocaa has built up global stations in the Philippines,
            Indonesia, Germany, Russia, and other countries and the strategic
            layout of brand globalization and product localization has been
            realized step by step.
          </p>
          <div className="md:flex items-center md:mt-10">
            <div className="basis-1/2">
              <img src="static/images/about2.jpg" alt="" />
            </div>
            <div className="basis-1/2 md:pl-10">
              <h3 className="text-xl font-medium mt-10">Our Mission</h3>
              <p className="mt-5">
                With an attitude that young people should have, Coocaa aims to
                explore the intelligent lifestyle brought by technology and the
                Internet, integrate into the global culture with a sharp-eyed
                design and equal communication, and constantly develop trendy
                technological products for young people.
              </p>
              <h3 className="text-xl font-medium mt-10">Brand Proposition</h3>
              <p className="mt-5">
                The brand concept of coocaa "Play in my style" contains a deep
                self-understanding and a bold imagination of the external
                unknown world. Coocaa is born for those who are imaginative and
                dare to challenge the conventions. Coocaa is willing to explore
                the wonderful unknown world with them.
              </p>
              <h3 className="text-xl font-medium mt-10">Brand Positioning</h3>
              <p className="mt-5">
                Positioning the brand as a “Coo-life player” to expand its reach
                while complementing the current TV business. To strive for a
                better future; to protect the earth and live a simple life;
                Staying positive about the future; Investing in your passions.
              </p>
              <h3 className="text-xl font-medium mt-10">Brand Personalities</h3>
              <p className="mt-5">
                Passion: Always be passionate about life, embrace the future
                with personality and passion.
              </p>
              <p className="mt-5">
                Optimism: Never give up on life and work, keep determined and
                optimistic.
              </p>
              <p className="mt-5">
                Exploration: Say “No” to the changeless status quo, dig into the
                unknown fields.
              </p>
              <p className="mt-5">
                Elegant Taste: Say "No" to dullness and boredom, hold on to
                elegant and humorous expression.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default About;
