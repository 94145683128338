import { Fragment } from "react";
import { Link, Navigate } from "react-router-dom";
import { useCart } from "react-use-cart";
import { useNavigate } from "react-router-dom";
import "./Cart.scss";

function Cart(props) {
  const authCtx = props.authCtx;
  const {
    isEmpty,
    totalUniqueItems,
    items,
    cartTotal,
    updateItemQuantity,
    removeItem,
  } = useCart();
  const navigate = useNavigate();
  const goToCheckout = () => navigate("/checkout");
  return (
    <section>
      <div className="seperator mt-20"></div>
      <div className="breadcrumb py-2 px-5 sm:px-20 2xl:px-32">
        <Link to="/" className="item">
          Home
        </Link>
        <div className="item">-</div>
        <div className="item current">Cart</div>
      </div>
      <div className="seperator mb-10"></div>
      <section id="cartSection" className="px-5 sm:px-20 2xl:px-32">
        {isEmpty ? (
          <div className="empty_cart">
            <center>Your cart is empty</center>
          </div>
        ) : (
          <Fragment>
            <h1 className="heading h2">Cart ({totalUniqueItems})</h1>
            <hr />
            <div className="main_row">
              <ul>
                {items.map((item) => (
                  <li key={item.id} className="py-5 sm:py-10 px-5 border mt-5">
                    <img src={item.image} alt={item.name} />
                    <div className="col">
                      <h1>{item.name}</h1>
                      <h2 className="mt-2">₹{item.itemTotal}</h2>
                      <div className="row mt-3">
                        <div className="quantity_selector">
                          <button
                            onClick={() =>
                              updateItemQuantity(item.id, item.quantity - 1)
                            }
                          >
                            -
                          </button>
                          <p>{item.quantity}</p>
                          <button
                            onClick={() =>
                              updateItemQuantity(item.id, item.quantity + 1)
                            }
                          >
                            +
                          </button>
                        </div>
                        <button
                          className="remove_btn"
                          onClick={() => removeItem(item.id)}
                        >
                          &times; <small>Remove</small>
                        </button>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
              <div className="summary border mt-5 p-5">
                <ul>
                  <li>
                    <p>Total</p>
                    <p>₹{cartTotal}</p>
                  </li>
                  <li>
                    <p>Delivery charges</p>
                    <p>₹0</p>
                  </li>
                  <li>
                    <p>Discount</p>
                    <p>₹0</p>
                  </li>
                  <li>
                    <div className="my-2 w-full border-t"></div>
                  </li>
                  <li className="font-medium">
                    <p>Grand Total</p>
                    <p>₹{cartTotal}</p>
                  </li>
                  <li>
                    <button
                      onClick={
                        authCtx.isLoggedIn ? goToCheckout : authCtx.showLogin
                      }
                      className="p_btn w-full mt-5"
                    >
                      CHECKOUT
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </Fragment>
        )}
      </section>
    </section>
  );
}

export default Cart;
