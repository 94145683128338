import { Fragment } from "react";
import { Helmet } from "react-helmet";

function PrivacyPolicy() {
  return (
    <Fragment>
      <Helmet>
        <title>Coocaa Televisions - Privacy Policy</title>
        <meta name="description" content="We understand the importance of personal information to you and will do our best to keep your personal information safe and reliable. We are committed to maintaining your trust in us and to protecting your personal information by following principles." />
        {/* <meta name="keywords" content="" /> */}
      </Helmet>
    <div className="mt-32 px-5 sm:px-20 2xl-px-32">
      <h1 className="heading">Privacy Policy</h1>
      <hr />
      <p className="text-gray-500 mt-2">August 18, 2020 Updated</p>
      <p className="text-gray-500">Effective August 18, 2020</p>

      <div className="text-gray-800 leading-6">
        <p className="mt-5">
          Shenzhen Chuangwei-RGB Electronic co.,LTD (“we”“Coocaa TV”) attaches
          great importance to user privacy and personal information protection.
          When you use our services, you may collect and use your relevant
          information. We hope to explain to you through this privacy policy
          that we obtain, manage and Policies and measures to protect users'
          personal information. By agreeing or voluntarily providing your
          personal information, you agree that we will use, manage and keep your
          personal information for the purposes and in the manner set forth in
          these terms.
        </p>
        <p className="mt-5">
          We understand the importance of personal information to you and will
          do our best to keep your personal information safe and reliable. We
          are committed to maintaining your trust in us and to protecting your
          personal information by following principles: the principle of equal
          rights and responsibilities, the principle of clarity of purpose, the
          principle of optional consent, the principle of least necessary, the
          principle of ensuring safety, the principle of subject participation,
          the principle of openness and transparency, etc. At the same time, we
          promise that we will take appropriate security measures to protect
          your personal information in accordance with the industry's mature
          security standards.
        </p>

        <p className="mt-5">
          When you use our products and/or services, or continue to use this
          policy after it is updated, you shall be deemed to have read,
          understood and agreed to this policy and its updated version. If you
          do not agree to any of the terms of this policy, you have the right to
          immediately stop using our products and/or services. This policy will
          help you understand the following:
        </p>
        <p className="mt-5"></p>
        <ul>
          <li>1. What information will we collect</li>
          <li>2. How do we use information</li>
          <li>
            3. How do we share, transfer, and publicly disclose information
          </li>
          <li>4. How do we store and protect your personal information</li>
          <li>5. Your rights</li>
          <li>6. Protection of personal information of minors</li>
          <li>7. Notification and amendment</li>
        </ul>
        <p className="mt-5"></p>

        <h3 className="text-lg font-medium mt-10">
          1. What information (whether or not it is personal information) will
          we collect
        </h3>
        <p className="mt-5">
          Personal information: refers to all kinds of information recorded
          electronically or otherwise that can identify a particular natural
          person or reflect the activities of a particular natural person,
          either individually or in combination with other information.
        </p>
        <p className="mt-5">
          We will only collect and use your relevant information for the
          following purposes and ways:
        </p>
        <ul className="mt-5" style={{ textIndent: "2em" }}>
          <li>
            1) The information you provide when you register a user, includes
            media name, media category, personal name, title, location, detailed
            address, phone number, and email address.
          </li>
          <li>
            2) Information from device. We may also obtain information about
            your use of our services, including device information, location
            information, etc., through software in your device and other means.
            When you open our website and fill in the registration information,
            we will read your computer or mobile device related information,
            including but not limited to the operating system, network operator,
            unique device identification; When you use location-related services
            (such as using GPS current location to find nearby Coocaa TV sales
            stores), we may obtain your geographical location information
            through IP address, GPS.
          </li>
        </ul>
        <p className="mt-5">
          Please note that individual device information, service log
          information, and other information that does not identify a particular
          natural person is not your personal information. if we combine this
          kind of personal information and other information used to identify a
          specific natural person identity, or combined with personal
          information, it is during the period of using, such as the personal
          information will be treated as personal information, in addition to
          obtain your authorization or otherwise prescribed by laws and
          regulations, we will this kind of information do anonymous, to
          identify with.
        </p>
        <p className="mt-5">
          You have the option not to provide certain types of information, but
          please note that this may affect your use of certain service features.
        </p>

        <h3 className="text-lg font-medium mt-10"> 2. How do we use information</h3>

        <p className="mt-5">
          Personal information is collected for the purpose of providing
          services to you and ensuring that we comply with applicable laws. You
          consent to our handling of personal Information for the purposes of
          this Privacy Policy and disclosure of personal Information to us and
          third party service providers.
        </p>

        <ul className="mt-5" style={{ textIndent: "2em" }}>
          <li>
            1) Your information will be used for the usage scenarios specified
            in the "what information do we collect" clause.
          </li>
          <li>
            2) Your information may be used in the following scenarios that are
            directly or reasonably related to the purpose for which we will
            collect the information:
            <ul style={{ textIndent: "4em" }}>
              <li>
                (1) We may store the collected information on the server side
                based on the user's personal information statistics.
              </li>
              <li>
                (2) We may send you a message, make a phone call or send you an
                email.
              </li>
            </ul>
          </li>
          <li>
            3) Whoever than with "what information will we collect" referred to
            in clause purpose has direct or the scope of reasonable correlation
            using your information, or to use information for the policy did not
            indicate the other USES, or is to be based on specific purpose to
            collect the information for other purposes, will be to confirm that
            the agreement is specific scenarios, behavior, and update this
            privacy agreement form again your express consent.
          </li>
          <li>
            4) Exceptions to consent. You fully understand and agree that we may
            collect and use your personal information without your authorized
            consent:
            <ul style={{ textIndent: "4em" }}>
              <li>
                {" "}
                (1) Related to national security and national defense security;
              </li>
              <li>
                (2) Related to public safety, public health, or major public
                interest;
              </li>
              <li>
                (3) Related to judicial or administrative law enforcement such
                as criminal investigation, prosecution, trial and execution of
                judgment;
              </li>
              <li>
                (4) Ror the purpose of safeguarding your or other personal life,
                property and other significant legitimate rights and interests,
                but it is difficult to obtain my consent;
              </li>
              <li>
                (5) Personal information that you disclose to the public by
                yourself;
              </li>
              <li>
                (6) The collection of personal information from legally
                disclosed information, such as legitimate news reports,
                government information disclosure and other channels.
              </li>
              <li>
                (7) As required by the execution and performance of the relevant
                agreement or other written document with you;
              </li>
              <li>
                (8) Necessary to maintain the safe and stable operation of the
                product and/or service provided, such as the detection and
                disposal of faults in the product and/or service;
              </li>
              <li> (9)Is necessary for lawful news reporting;</li>
              <li>
                (10) When it is necessary for an academic research institution
                to carry out statistics or academic research in the public
                interest and provide the results of academic research or
                description to the outside world, the personal information
                contained in the results shall be de-labeled;
              </li>
              <li>
                (11)Other circumstances specified by laws and regulations.
              </li>
            </ul>
          </li>
        </ul>

        <h3 className="text-lg font-nedium mt-10">
          3. How do we share, transfer, and publicly disclose information
        </h3>
        <h3 className="mt-5">
          <ul style={{ textIndent: "2em" }}>
            <li>
              1) Share. We will not share your personal information with any
              company, organization or person other than skyvision TV operator,
              except as follows:
              <ul style={{ textIndent: "4em" }}>
                <li>
                  (1) Sharing under legal circumstances. We may share your
                  personal information in accordance with the provisions of laws
                  and regulations, litigation, dispute resolution needs, or as
                  required by administrative or judicial authorities in
                  accordance with the law.{" "}
                </li>
                <li>
                  (2) Share with your express consent. With your express
                  consent, we will share your personal information with others.
                </li>
                <li>
                  (3) Sharing with affiliate companies. For us based on Coocaa
                  TV website service/product provided to you, it is recommended
                  that you might be interested in information, identify the user
                  account is unusual, protect associated companies or any other
                  user or the personal property safety of the public from harm,
                  your personal information may be associated with our company
                  and/or its designated service providers to share. We will only
                  share necessary personal information and are bound by the
                  purposes stated in this Privacy Policy.
                </li>
                <li>
                  (4) Share with authorized partners. For the purposes stated in
                  this policy, certain of our services will be provided by
                  authorized partners with whom we may share certain of your
                  personal information to provide better user services and
                  experiences. We will only share your personal information for
                  lawful, legitimate, necessary and specific purposes, and we
                  will only share the personal information necessary for
                  authorized partners to provide services. Currently, our
                  authorized partners include the following types:
                  <ul style={{ textIndent: "6em" }}>
                    <li>
                      I. Authorized partner for advertising and analysis
                      services. We will not share your personal information with
                      analytics partners unless we have your permission. We may
                      entrust these partners to process information related to
                      the validity of the service, but we may not provide your
                      personally identifiable information, or we may de-identify
                      such information so that it does not identify you
                      personally. Such partners may combine the above
                      information with other data that they legally obtain to
                      implement the service recommendations we have entrusted to
                      them.
                    </li>
                    <li>
                      Ⅱ. Suppliers, service providers and other partners. We
                      send information to suppliers, service providers and other
                      partners in support of our business, including the
                      technical infrastructure services we have commissioned,
                      the analysis of how our services are used, the measurement
                      of their effectiveness or the conduct of academic research
                      and investigations.
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              We will enter into strict confidentiality agreements with our
              authorized partners to take strict data protection measures to
              handle personal information in accordance with the purpose of our
              delegation, service instructions, this privacy policy and any
              other relevant confidentiality and security measures.
            </li>
            <li>
              2) Transfer.We will not transfer your personal information to any
              company, organization or individual, except as follows:
              <ul style={{ textIndent: "4em" }}>
                <li>(1) Obtain your express consent;</li>
                <li>
                  (2) In the event of a merger, acquisition or bankruptcy
                  liquidation, if the transfer of personal information is
                  involved, we will require the new company or organization
                  holding your information to continue to be bound by this
                  privacy policy; otherwise, we will require the company or
                  organization to obtain your authorization again;
                </li>
                <li>
                  (3) Comply with mandatory requirements of laws and
                  regulations, court orders or government departments.
                </li>
              </ul>
            </li>
            <li>
              {" "}
              3) Public disclosure.we will only publicly disclose your personal
              information under the following circumstances:
              <ul style={{ textIndent: "4em" }}>
                <li> (1) Obtain your express consent;</li>
                <li>
                  {" "}
                  (2) Legally based disclosure: we may publicly disclose your
                  personal information to the extent required by law, legal
                  proceedings, litigation, or government authorities.
                </li>
                (3) Exceptions with prior authorization for sharing,
                transferring, or publicly disclosing personal information.
                <br />
                Under the following circumstances, the sharing, transfer and
                public disclosure of your personal information does not require
                prior permission from you:
                <ul style={{ textIndent: "6em" }}>
                  <li>I. Related to national security and defense security;</li>
                  <li>
                    Ⅱ. Related to public safety, public health, and major public
                    interests;
                  </li>
                  <li>
                    Ⅲ. Cooperate with the investigation of the judicial and
                    administrative organs of the state, relating to crimes,
                    investigation, prosecution, trial and execution of
                    judgments;
                  </li>
                  <li>
                    {" "}
                    Ⅳ. For the protection of your or other personal life,
                    property and other material property rights and interests;
                  </li>
                  <li>
                    V. Other circumstances in which public interests are
                    safeguarded;
                  </li>
                  <li>
                    Ⅵ. Personal information that you disclose to the public on
                    your own.
                  </li>
                </ul>
              </ul>
            </li>
            <li>
              {" "}
              Please note that if we take technical measures and other necessary
              measures to process the personal information so that the data
              recipient cannot re-identify and recover the specific individual,
              then the sharing, transfer and public disclosure of such data
              after such processing does not require further notice to you and
              your consent.
            </li>
          </ul>
        </h3>
        <h3 className="text-lg font-nedium mt-10"> 4. How do we store and protect your personal information</h3>
        <ul className="mt-5" style={{ textIndent: "2em" }}>
          <li>
            1) Personal information storage
            <ul style={{ textIndent: "4em" }}>
              <li>
                {" "}
                (1) General storage period: unless otherwise expressly provided
                by law or permitted by you, we will retain your personal
                information only for the period required for the purposes set
                forth in this privacy policy. In order to maintain continuity of
                service and consistency of user experience, personal information
                is usually not deleted immediately after a single service is
                completed.
              </li>
              <li>
                (2) Your personal information storage location: the personal
                information collected and generated by us within the People's
                Republic of China will be stored in the People's Republic of
                China.
              </li>
            </ul>
          </li>
          <li>
            {" "}
            2) How do we ensure your information security
            <ul style={{ textIndent: "4em" }}>
              <li>
                {" "}
                (1) Data security: we take appropriate technical measures to
                protect the security of personal information, and we have used
                industry-standard security measures to protect the personal
                information you provide from unauthorized access, use,
                modification, public disclosure, damage or loss of the data.
                Some of the security measures we use include data anonymity,
                data encryption, firewalls, and data access authorization
                control. We attach great importance to data security, so we
                regularly review and update the security mechanisms for data
                protection to provide effective means of protection against data
                abuse.
              </li>
              <li>
                (2) Your account has security protection function, please keep
                your account information properly. However, please also
                understand that the Internet environment is not 100% secure due
                to technical limitations and various malicious ACTS that may
                exist, and there is no "perfect security measures" on the
                information network.
              </li>
              <li>
                (3) In case of information leakage and other security incidents,
                we will activate the corresponding emergency plan, try our best
                to prevent the expansion of the incident, and timely inform you
                of the relevant situation of the incident in the form of push
                notification, announcement and so on.
              </li>
              <li>
                (4) If you believe that the security of your data has been
                compromised, or if you would like to know more about the
                measures we use to protect our data, please contact us by using
                the information in contact us below.
              </li>
            </ul>
          </li>
        </ul>

        <h3 className="text-lg font-nedium mt-10">5. Your rights</h3>
        <p className="mt-5">
          We guarantee that you have the following rights with respect to your
          personal information:
        </p>
        <ul className="mt-5" style={{ textIndent: "2em" }}>
          <li>
            1) You have the right to access, correct or delete your personal
            information
            <ul style={{ textIndent: "4em" }}>
              <li>
                (1)Access your personal information. You have the right to
                access the information you have submitted. You can contact us by
                email to obtain the information you have submitted.
              </li>
              <li>
                (2)Correct your personal information. You have the right to
                correct or request us to correct any errors in your personal
                information that we have handled.
              </li>
              <li>
                (3)Delete your personal information. You may request us to
                delete your personal information in the following situations:
                <ul style={{ textIndent: "6em" }}>
                  <li>
                    I. If we deal with personal information in violation of laws
                    and regulations;
                  </li>
                  <li>
                    Ⅱ. If we deal with personal information in a way that
                    violates our agreement with you;
                  </li>
                </ul>
                <p>
                  When you remove information from our services, we may not
                  immediately remove the information from the backup system, but
                  we will remove the information when the backup is updated.
                </p>
              </li>
            </ul>
          </li>
        </ul>
        <ul className="mt-5" style={{ textIndent: "2em" }}>
          <li>
            2) Change the scope of your authorization consent
            <p>
              You may, by submitting a request, revoke your license to authorize
              us to collect, use, or disclose the personal information in our
              possession. But please understand that we will not be able to
              provide the services corresponding to the authorization.
            </p>
          </li>
        </ul>

        <h3 className="text-lg font-nedium mt-10">6. Personal information protection for minors</h3>
        <p className="mt-5">
          If you are a minor, please obtain the consent of your parent or
          guardian before using our services. We suggest that you ask your
          parent or guardian to read this privacy policy carefully. We will only
          use the personal information legally collected about a minor if it is
          permitted by law, with the explicit consent of the guardian and
          necessary to protect the minor. If we find ourselves collecting
          personal information about minors without first obtaining verifiable
          parent or guardian, we will try to delete the data as soon as
          possible. If a parent or guardian has reason to believe that a minor
          has submitted personal information to us without their consent, please
          contact us to ensure that such personal information is deleted and
          that a minor cancels his or her subscription to our services.
        </p>
        <h3 className="text-lg font-nedium mt-10">7. Notification and amendment</h3>
        <ul className="mt-5" style={{ textIndent: "2em" }}>
          <li>
            1) To provide you with better services, this privacy policy will
            also be updated accordingly. However, we will not reduce your rights
            under this policy without your express consent. We will issue an
            updated version on this platform and notify you of such updates in
            an appropriate manner before they take effect.
          </li>
          <li>
            2) We also provide more prominent notifications of major changes
            (usually by making specific changes to our privacy policy, such as
            special notices on the browsing page). Material changes referred to
            in this policy include, but are not limited to:
            <ul style={{ textIndent: "4em" }}>
              <li>
                (1) There has been a major change in our service model. Such as
                the purpose of processing personal information, the type of
                personal information processed, and the way of using personal
                information;
              </li>
              <li>
                (2) We have made significant changes in ownership structure,
                organizational structure, etc. Such as business adjustment,
                bankruptcy mergers and acquisitions caused by the change of
                owners;
              </li>
              <li>
                (3) The main objects of personal information sharing, transfer
                or public disclosure change;
              </li>
              <li>
                (4) There is a significant change in your right to participate
                in the processing of personal information and the way you
                exercise it;
              </li>
              <li>
                (5) When our responsible departments, contact information and
                complaint channels for personal information security change;
              </li>
              <li>
                (6) when the personal information security impact assessment
                report indicates high risk.
              </li>
              <p>
                If you have any comments or questions about this privacy policy,
                or if you have any questions about our collection, use or
                disclosure of your personal information, please contact us by
                email to the email address below.
              </p>
              <p>
                If you select the check box button of this agreement, you shall
                be deemed to have fully accepted this agreement. Please confirm
                that you know and fully understand all contents of this
                agreement before clicking.
              </p>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    </Fragment>
  );
}

export default PrivacyPolicy;
