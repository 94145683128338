import { Link } from "react-router-dom";
import { useCart } from "react-use-cart";
import "./Checkout.scss";

function Checkout() {
  const { cartTotal } = useCart();
  return (
    <section>
      <div className="seperator mt-20"></div>
      <div className="breadcrumb py-2 px-5 sm:px-20 2xl:px-32">
        <Link to="/" className="item">
          Home
        </Link>
        <div className="item">-</div>
        <Link to="/cart" className="item">
          Cart
        </Link>
        <div className="item">-</div>
        <div className="item current">Checkout</div>
      </div>
      <div className="seperator mb-10"></div>
      <section id="checkoutSection" className="px-5 sm:px-20 2xl:px-32 mb-44">
        <h1 className="heading h2">Checkout</h1>
        <hr />
        <div className="main_row">
          <div className="address_selector mt-5">
            <h2>Enter your address</h2>
            <form>
              <div className="group">
                <label htmlFor="name">Full Name</label>
                <input type="text" name="name" id="name" />
              </div>
              <div className="group">
                <label htmlFor="phone">Phone</label>
                <input type="tel" name="phone" id="phone" />
              </div>
              <div className="group">
                <label htmlFor="phone">Address</label>
                <input type="tel" name="phone" id="phone" />
              </div>
              <div className="group">
                <label htmlFor="phone">Locality/Area/Street</label>
                <input type="tel" name="phone" id="phone" />
              </div>
              <div className="group">
                <label htmlFor="phone">State</label>
                <input type="tel" name="phone" id="phone" />
              </div>
              <div className="group">
                <label htmlFor="phone">City</label>
                <input type="tel" name="phone" id="phone" />
              </div>
              <div className="group">
                <label htmlFor="phone">Pin code</label>
                <input type="tel" name="phone" id="phone" />
              </div>
              <div className="group">
                <label htmlFor="address_type">Address Type</label>
                <select name="address_type" id="address_type">
                  <option value="1">Home</option>
                  <option value="2">Office</option>
                </select>
              </div>
            </form>
          </div>
          <div className="summary border mt-5 p-5">
            <ul>
              <li>
                <p>Total</p>
                <p>₹{cartTotal}</p>
              </li>
              <li>
                <p>Delivery charges</p>
                <p>₹0</p>
              </li>
              <li>
                <p>Discount</p>
                <p>₹0</p>
              </li>
              <li>
                <div className="my-2 w-full border-t"></div>
              </li>
              <li className="font-medium">
                <p>Grand Total</p>
                <p>₹{cartTotal}</p>
              </li>
              <li>
                <button className="p_btn w-full mt-5">PLACE ORDER</button>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </section>
  );
}

export default Checkout;
