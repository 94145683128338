import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "./../../auth-context";
import "./ChangePassword.scss";

function ChangePassword() {
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();
  const form = useRef();
  const savePassword = (e) => {
    e.preventDefault();
    const fd = new FormData(form.current);
    fetch(authCtx.baseUrl + "/generateNewPassword", {
      method: "POST",
      body: fd,
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        alert(data.message);
        navigate("/profile");
      });
  };
  return (
    <div
      id="changePassword"
      className="px-5 sm:px-16 2xl:px-28 py-20 md:py-32"
    >
      <h1 className="heading">Change Password</h1>
      <hr />
      <div className="profile_card p-5 xl:px-10 xl:py-6">
        <form onSubmit={savePassword} ref={form}>
          <table>
            <tbody>
              <tr>
                <td>New password:</td>
                <td>
                  <input type="password" name="password" required />
                </td>
              </tr>
              <tr>
                <td>Confirm password:</td>
                <td>
                  <input type="password" name="cnfpassword" required />
                </td>
              </tr>
            </tbody>
          </table>
          <button type="submit" className="p_btn">
            Change
          </button>
        </form>
      </div>
    </div>
  );
}

export default ChangePassword;
