import { Fragment } from "react";
import { Helmet } from "react-helmet";

function WarrantyPolicy() {
  return (
    <Fragment>
      <Helmet>
        <title>Product Warranty Information | Coocaa India</title>
        <meta name="description" content="Call 1800-12344-6666 for instructions on getting the defective unit repaired or replaced or send us an email to contactus@coocaatv.in" />
      </Helmet>
    <div className="mt-32 px-5 sm:px-20 2xl-px-32">
      <h1 className="heading">Warranty Policy</h1>
      <hr />
      <h2 className="text-2xl mt-5">COOCAA INDIA LIMITED WARRANTY</h2>
      <div className="text-gray-800 leading-6">
        <p className="mt-5">
          Thank you for purchasing COOCAA product! Your COOCAA LCD / LED
          Television (“unit”) will be repaired or replaced, at COOCAA‘s sole
          discretion, if it proves to be defective in material or workmanship
          under normal use within the warranty period, effective from the Date
          of Purchase of original consumer purchase of the unit. This warranty
          will be affected only when you purchased as new from an authorized
          dealer of COOCAA and effective only when used in INDIA. You will be
          required to provide dealer's dated bill of sale or delivery ticket as
          evidence of the Date of Purchase for proof of warranty.
        </p>
        <h2 className="mt-10 font-medium">
          FOR HOW LONG WILL YOUR COOCAA TV WARRANTY BE COVERED?{" "}
        </h2>
        <h3 className="mt-5 text-lg font-medium">
          1 Year from the Date of Purchase.
        </h3>
        <h3>(180 days for commercial, rental, or business use)* </h3>
        <h3 className="mt-5 text-lg font-medium">
          PARTS: ONE (1) Year from the Date of Purchase.
        </h3>
        <h3>(180 days for commercial, rental, or business use)* </h3>
        <p className="mt-5">
          Replacement Units and Repair Parts may be new or re-manufactured.
          Replacement Units and Repair Parts are warranted for the remaining
          portion of the original unit's warranty period. When you return a
          product to a COOCAA service center, you must ship the product in its
          original packaging or in packaging that affords an equal degree of
          protection, shipping damage is not covered under COOCAA warranty, for
          that reason COOCAA is not responsible for transportation costs to the
          service center, but COOCAA will cover return shipping to the same
          shipping address provided by customer where the product shipped from.
        </p>
        <h2 className="text-lg font-medium mt-10">
          BEFORE CALLING FOR SERVICE:
        </h2>
        <ul className="mt-5">
          <li className="mt-2">
            {" "}
            • Please make sure to have your unit's model number, serial number,
            and the date of purchase in hand.
          </li>
          <li className="mt-2">
            • You might be asked to provide credit card information for a
            non-warranty service fee, as a deposit for advanced shipment, or
            cost of unreturned loaner unit.
          </li>
          <li className="mt-2">
            • Please use the carton and packaging from that replacement unit in
            returning the defective unit to an COOCAA authorized service center
            once replacement unit is received.
          </li>
          <li className="mt-2">
            • COOCAA will take responsibility of Parts and service in accordance
            with the COOCAA warranty. Other service requirements will be at the
            owner's expense.
          </li>
          <li className="mt-2">
            • You must obtain an RMA (Return Material Authorization) number
            before you return any unit to the COOCAA authorized service center!
            You will be getting such RMA number when COOCAA customer service
            representative verified the unit is valid for warranty services.
          </li>
        </ul>
        <h2 className="text-lg font-medium mt-10">
          THIS LIMITED WARRANTY DOES NOT APPLY TO:
        </h2>
        <ul className="mt-5">
          <li className="mt-2">
            • damage resulting from mis-operation, including but not limit to
            improper installation, incorrect or insufficient AC supply,
            non-approval adjustment, installation or repair of related
            equipment.
          </li>
          <li className="mt-2">• damage in shipping or transit.</li>
          <li className="mt-2">
            • improper set-up or adjustment on the TV by user, or damages caused
            by improper installation / connection.
          </li>
          <li className="mt-2">
            • weak signal or slow speed internet reception problems which is not
            caused by the TV • damage from misuse, abuse, negligence, abnormal
            wear and tear, extreme environments (i.e.: too hot / too cold /
            moisture/ dusty……… areas.), cosmetic damage, mishandling.
          </li>
          <li className="mt-2">
            • replacement of batteries on the remote control.
          </li>
          <li className="mt-2">
            • act of nature or God damage out of our control.{" "}
          </li>
          <li className="mt-2">
            • repair or replacement of warranted parts by other than an COOCAA
            authorized service centers.
          </li>
          <li className="mt-2">
            {" "}
            •units purchased or serviced outside of INDIA.
          </li>
          <li className="mt-2">
            •units the serial numbers from original manufactures have been
            removed, defaced or changed.
          </li>
        </ul>
        <p className="mt-5">
          The cost of repair or replacement under these excluded circumstances
          shall be borne by the consumer.
        </p>
        <p className="mt-5">
          Call{" "}
          <a
            className="hover:underline text-blue-700"
            href="tel:+1800-12344-6666"
          >
            1800-12344-6666
          </a>{" "}
          for instructions on getting the defective unit repaired or replaced or
          visit our website at{" "}
          <a
            className="hover:underline text-blue-700"
            href="http://www.coocaatv.in"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.coocaatv.in
          </a>
          or send us an email to{" "}
          <a
            className="hover:underline text-blue-700"
            href="mailto:contactus@coocaatv.in"
          >
            contactus@coocaatv.in
          </a>
        </p>
      </div>
    </div>
    </Fragment>
  );
}

export default WarrantyPolicy;
