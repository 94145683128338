import { useEffect } from "react";
import { useLocation } from "react-router";
import { useLocomotiveScroll } from "react-locomotive-scroll";

const ScrollToTop = (props) => {
  const location = useLocation();
  const { scroll, isReady } = useLocomotiveScroll();
  useEffect(() => {
    // props.onRouteChange(location);
    if(isReady) scroll.scrollTo(0, { duration: 0, disableLerp: true });
  }, [location]);

  return <>{props.children}</>;
};

export default ScrollToTop;
