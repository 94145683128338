import { useState, useEffect, Fragment } from "react";
import { Link, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./Blogs.scss";

function Blogs(props) {
  const authCtx = props.authCtx;

  const params = useParams();

  const [blogs, setBlogs] = useState([]);
  function getBlogs() {
    fetch(authCtx.baseUrl + "blogs")
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setBlogs(data);
      });
  }

  useEffect(() => {
    getBlogs();
    console.log("params", params);
  }, [params]);
  return (
    <Fragment>
      {!params.blogId && (
        <Helmet>
          <title>Blogs | Coocaa India</title>
          <meta
            name="description"
            content="Click here to read more about Coocaa Smart TVs, new product launches and features."
          />
        </Helmet>
      )}
      <section id="blog">
        <div className="seperator mt-20"></div>
        <div className="breadcrumb py-2 px-5 sm:px-20 2xl:px-32">
          <Link to="/" className="item">
            Home
          </Link>
          {params.blogId ? (
            <Fragment>
              <div className="item">-</div>
              <Link to="/blogs" className="item">
                Blogs
              </Link>
              <div className="item">-</div>
              <div className="item current">{params.blogId}</div>
            </Fragment>
          ) : (
            <Fragment>
              <div className="item">-</div>
              <div className="item current">Blogs</div>
            </Fragment>
          )}
        </div>
        <div className="seperator mb-10"></div>
        <section className="px-5 sm:px-16 2xl:px-28">
          {!params.blogId && (
            <h1 className="heading">
              Blogs
              <hr />
            </h1>
          )}
          <div
            className={params.blogId ? "main_row" : "main_row mt-8 md:mt-20"}
          >
            <div
              className={params.blogId ? "blog_wrapper mt-10" : "blogs_wrapper"}
            >
              {blogs.map((blog) =>
                params.blogId ? (
                  blog.slug == params.blogId && (
                    <Fragment>
                      <Helmet>
                        <title>{blog.meta_title}</title>
                        <meta
                          name="description"
                          content={blog.meta_description}
                        />
                      </Helmet>
                      <div key={blog.id}>
                        <h1 className="mb-2">{blog.title}</h1>
                        <hr />
                        <img className="mt-10 mb-2" src={blog.image} alt="" />
                        <small>{blog.published_on}</small>
                        <p
                          className="mt-10"
                          dangerouslySetInnerHTML={{
                            __html: blog.content_html,
                          }}
                        ></p>
                      </div>
                    </Fragment>
                  )
                ) : (
                  <Link
                    to={"/blogs/" + blog.slug}
                    className="blogCard border"
                    key={blog.id}
                  >
                    <img src={blog.image} alt="" />
                    <div className="body p-5">
                      <h4>{blog.published_on}</h4>
                      <h2 className="m-0">{blog.title}</h2>
                      {/* <p>{blog.heading}</p> */}
                    </div>
                  </Link>
                )
              )}
            </div>
            {params.blogId && (
              <div className="sideNav mt-10">
                <div className="header">RECENT BLOGS</div>
                <div className="px-4 2xl:px-7 py-4 2xl:py-6">
                  {blogs.map((blog) => (
                    <Link
                      to={"/blogs/" + blog.slug}
                      key={blog.id}
                      className="navBlogCard"
                    >
                      <img src={blog.image} alt="" />
                      <div className="col">
                        <p>{blog.title}</p>
                        <small>{blog.published_on}</small>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            )}
          </div>
        </section>
      </section>
    </Fragment>
  );
}

export default Blogs;
