import { useState, useEffect, Fragment, useRef } from "react";
import { useParams } from "react-router-dom";
import { useCart } from "react-use-cart";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ProductCard from "../ProductCard/ProductCard";
import "./Product.scss";
import { useNavigate } from "react-router-dom";
import { gsap } from "gsap/all";
import Collapse from "./Collapse";
import Carousel from "./../Carousel/Carousel";
import ProductCarousel from "./ProductCarousel";

function Product(props) {
  const authCtx = props.authCtx;
  const params = useParams();
  const currImg = useRef();
  const [product, setProduct] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [maxReviewsLength, setMaxReviewsLength] = useState(4);
  const { addItem } = useCart();
  const navigate = useNavigate();
  const isMobile = window.matchMedia(
    "only screen and (max-width: 760px)"
  ).matches;
  const specifications = [
    {
      name: "General",
      key: "general_specs",
    },
    {
      name: "Video Features",
      key: "video_specs",
    },
    {
      name: "Audio Features",
      key: "audio_specs",
    },
    {
      name: "Smart Tv Features",
      key: "smart_tv_specs",
    },
    {
      name: "Connectivity Features",
      key: "connectivity_specs",
    },
    {
      name: "Power Features",
      key: "power_specs",
    },
    {
      name: "Dimensions",
      key: "dimensions_specs",
    },
    {
      name: "Additional Features",
      key: "additional_features",
    },
    {
      name: "Warranty",
      key: "warranty",
    },
    {
      name: "Installation & Demo",
      key: "installation_demo_details",
    },
  ];
  const specsKeyMapper = {
    in_the_box: "In The Box",
    model_name: "Model Name",
    screen_type: "Screen type",
    hd_technology_resolution: "HD Technology & Resolution",
    smart_tv: "Smart TV",
    motion_sensor: "Motion Sensor",
    hdmi: "HDMI",
    html: "HDMI",
    usb: "USB",
    wifi_type: "Wi-Fi type",
    build_in_wifi: "Build in Wi-Fi",
    launch_year: "launch Year",
    wall_mount_included: "Wall Mount Included",
    brightness: "Brightness",
    contrast_ratio: "Contrast Ratio",
    picture_engine: "Picture Engine",
    view_angle_degree: "View Angle",
    led_display_type: "LED Display Type",
    aspect_ratio: "Aspect Ratio",
    refresh_rate: "Refresh Rate",
    other_video_features: "Other Video Features",
    speaker: "Number of Speakers",
    sound_tech: "Sound Technology",
    speaker_output_rms: "Speaker Output RMS",
    sound_mode: "Sound Mode",
    other_audio_features: "Other Audio Features",
    number_of_cores: "Number of Cores",
    processor: "Processor",
    graphic_processor: "Graphic Processor",
    ram: "Ram Capacity",
    storage_memory: "Storage Memory",
    clocking_speed: "Clocking Speed",
    netflix: "Netflix",
    youtube: "Youtube",
    disney_plus_hotstar: "Disney+ Hotstar",
    supported_apps_others: "Supported App - Others",
    os: "Operating System",
    screen_mirroring: "Screen Mirroring",
    bluetooth: "Bluetooth",
    ethernet: "Ethernet (RJ45)",
    other_internet_features: "Other Internet Features",
    requirement: "Power Requirement",
    consumption: "Power Consumption",
    width_height_depth_without_stand: "Width x Height x Depth (without stand)",
    weight_without_stand: "Weight (without stand)",
    width_height_depth_with_stand: "Width x Height x Depth (with stand)",
    weight_with_stand: "Weight (with stand)",
    stand_type: "Stand Type",
    summary: "Warranty Summary",
    covered: "Covered in Warranty",
    not_covered: "Not Covered in Warranty",
    service_type: "Warranty Service Type",
  };
  function getProduct() {
    fetch(authCtx.baseUrl + "products/" + params.slug)
      .then((res) => {
        if (res.status != 200) {
          navigate("/");
        }
        return res.json();
      })
      .then((data) => {
        console.log(data);
        setProduct(data);
      });
  }
  function getAllProducts() {
    fetch(authCtx.baseUrl + "products")
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setAllProducts(data);
      });
  }
  function addToCart() {
    addItem({
      id: product.id,
      name: product.name,
      price: product.price,
      image: product.image,
      slug: product.slug,
      category: product.category,
      sub_category: product.sub_category,
      size: product.size,
      sku: product.sku,
    });
    navigate("/cart");
  }
  function showThisImg(img) {
    gsap.fromTo(
      currImg.current,
      {
        opacity: 0,
        scale: 0.98,
      },
      {
        opacity: 1,
        scale: 1,
        duration: 0.5,
        ease: "sine",
        onStart: () => {
          currImg.current.src = img;
        },
      }
    );
  }
  function showMore() {
    setMaxReviewsLength((value) => {
      return value + 4;
    });
  }
  useEffect(() => {
    getProduct();
    getAllProducts();
  }, [params]);
  return (
    <Fragment>
      <Helmet>
        <title>{product.meta_tags && product.meta_tags[0]?.title}</title>
        <meta
          name="description"
          content={product.meta_tags && product.meta_tags[0]?.description}
        />
        <meta
          name="keywords"
          content={product.meta_tags && product.meta_tags[0]?.keywords}
        />
      </Helmet>
      <div className="seperator mt-20"></div>
      <div className="breadcrumb py-2 px-5 sm:px-20 2xl:px-32">
        <Link to="/" className="item">
          Home
        </Link>
        <div className="item">-</div>
        <Link to={"/products/" + product.category?.slug} className="item">
          {product.category?.name}
        </Link>
        <div className="item">-</div>
        <Link to={"/products/" + product.sub_category?.slug} className="item">
          {product.sub_category?.name}
        </Link>
        <div className="item">-</div>
        <div className="item current">{product.name}</div>
      </div>
      <div className="seperator sm:mb-10"></div>
      <section className="py-10 px-5 sm:px-20 2xl:px-32">
        <div className="product_top_row">
          <div className="images_wrapper">
            <img
              src={product.image}
              alt={product.meta_tags && product.meta_tags[0]?.alt_tags}
              ref={currImg}
            />
            <div className="gallery mt-5">
              <img
                src={product.image}
                onClick={() => showThisImg(product.image)}
              />
              {product.gallery?.map((img) => (
                <img
                  key={img.image}
                  src={img.image}
                  onClick={() => showThisImg(img.image)}
                />
              ))}
            </div>
          </div>
          <div className="details mt-5 sm:mt-0 sm:pl-20">
            {product.is_new_launch ? (
              <svg
                className="new_launch_badge"
                width="42"
                height="21"
                viewBox="0 0 42 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M34.5456 18.47L41.0082 11.0499C41.1647 10.8824 41.2566 10.6464 41.262 10.3966C41.2673 10.1469 41.186 9.90522 41.0366 9.7287L34.5512 2.26725C34.4072 2.09633 34.2114 2 34.0073 2L0.769676 2C0.344376 2 -0.000183105 2.40805 -0.000183105 2.91171V17.7934C-0.000183105 18.0354 0.0808525 18.2673 0.225185 18.4382C0.369816 18.6091 0.565601 18.7051 0.769676 18.7051L34.03 18.7051C34.2205 18.7051 34.4042 18.6213 34.5456 18.47Z"
                  fill="#EB6039"
                />
                <path
                  d="M10.916 4.991H12.099V14H10.916L6.158 6.85V14H4.975V4.991H6.158L10.916 12.141V4.991ZM17.0421 14.117C16.0195 14.117 15.1875 13.779 14.5461 13.103C13.9048 12.427 13.5841 11.5343 13.5841 10.425C13.5841 9.31567 13.9091 8.42733 14.5591 7.76C15.2091 7.084 16.0498 6.746 17.0811 6.746C18.1211 6.746 18.9531 7.06667 19.5771 7.708C20.2098 8.34067 20.5261 9.151 20.5261 10.139C20.5261 10.3903 20.5088 10.6243 20.4741 10.841H14.7931C14.8278 11.5517 15.0488 12.115 15.4561 12.531C15.8721 12.9383 16.4008 13.142 17.0421 13.142C17.5275 13.142 17.9348 13.0337 18.2641 12.817C18.6021 12.6003 18.8491 12.3143 19.0051 11.959H20.2791C20.0538 12.583 19.6638 13.0987 19.1091 13.506C18.5631 13.9133 17.8741 14.117 17.0421 14.117ZM19.2911 9.983C19.2825 9.29833 19.0658 8.75233 18.6411 8.345C18.2165 7.929 17.6878 7.721 17.0551 7.721C16.4311 7.721 15.9111 7.92467 15.4951 8.332C15.0791 8.73067 14.8495 9.281 14.8061 9.983H19.2911ZM30.1764 6.863H31.3724L29.1234 14H27.9014L26.2894 8.332L24.6774 14H23.4424L21.1804 6.863H22.4024L24.0534 12.817L25.7304 6.863H26.9524L28.5384 12.83L30.1764 6.863Z"
                  fill="white"
                />
              </svg>
            ) : null}
            <h1>{product.name}</h1>
            <h2>Model: {product.model}</h2>
            <div className="price mt-5">
              <b>₹{product.price}</b>
              <s>₹{product.mrp}</s>
            </div>
            <div className="icons_row py-3 my-8">
              <div className="item">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22 12C22 17.5229 17.5229 22 12 22C6.47714 22 2 17.5229 2 12C2 6.47714 6.47714 2 12 2C17.5229 2 22 6.47714 22 12ZM10.8433 17.2949L18.2627 9.87556C18.5146 9.62363 18.5146 9.21512 18.2627 8.96319L17.3503 8.05081C17.0983 7.79883 16.6898 7.79883 16.4379 8.05081L10.3871 14.1015L7.56214 11.2766C7.3102 11.0246 6.90169 11.0246 6.64972 11.2766L5.73734 12.189C5.4854 12.4409 5.4854 12.8494 5.73734 13.1013L9.93089 17.2949C10.1829 17.5469 10.5913 17.5469 10.8433 17.2949Z"
                    fill="#313131"
                    fillOpacity="0.5"
                  />
                </svg>
                <p>Free Shipping</p>
              </div>
              <div className="item">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22 12C22 17.5229 17.5229 22 12 22C6.47714 22 2 17.5229 2 12C2 6.47714 6.47714 2 12 2C17.5229 2 22 6.47714 22 12ZM10.8433 17.2949L18.2627 9.87556C18.5146 9.62363 18.5146 9.21512 18.2627 8.96319L17.3503 8.05081C17.0983 7.79883 16.6898 7.79883 16.4379 8.05081L10.3871 14.1015L7.56214 11.2766C7.3102 11.0246 6.90169 11.0246 6.64972 11.2766L5.73734 12.189C5.4854 12.4409 5.4854 12.8494 5.73734 13.1013L9.93089 17.2949C10.1829 17.5469 10.5913 17.5469 10.8433 17.2949Z"
                    fill="#313131"
                    fillOpacity="0.5"
                  />
                </svg>
                <p>Secure Payment</p>
              </div>{" "}
            </div>
            <ul>
              {product.featured_specs?.split(",").map((li, i) => (
                <li key={i}>{li}</li>
              ))}
            </ul>
            {/* <h3 className="mb-3">Quantity</h3>
            <div className="quantity_selector">
              <button>-</button>
              <p>1</p>
              <button>+</button>
            </div> */}
            <div className="mt-10 lg:flex">
              {/* {product.buy_link == "" ? (
                <button className="buy_btn" onClick={addToCart}>
                  BUY NOW
                </button>
              ) : (
                <a target="_blank" href={product.buy_link}>
                  <button className="buy_btn">BUY NOW</button>
                </a>
              )} */}

              {product.buy_link != null ? (
                <Fragment>
                  <a target="_blank" href={product.buy_link}>
                    <button className="buy_btn">
                      <img src="static/images/flipkart.svg" alt="flipkart" />
                      SHOP ON FLIPKART
                    </button>
                  </a>{" "}
                  <div className="m-5"></div>
                </Fragment>
              ) : null}

              {product.amazon_buy_link != null ? (
                <a target="_blank" href={product.amazon_buy_link}>
                  <button className="buy_btn">
                    <img src="static/images/amazon.svg" alt="amazon" />
                    SHOP ON AMAZON
                  </button>
                </a>
              ) : null}
            </div>
          </div>
        </div>

        {product.reviews?.length > 0 ? (
          <Fragment>
            <div className="seperator mt-20"></div>
            <h1 className="text-center py-5 heading h2">Reviews</h1>

            <ProductCarousel
              items={product.reviews?.map((review, i) => (
                <div
                  key={"review" + i}
                  className="review px-4 lg:px-8 py-6 lg:py-8 rounded-md shadow-lg border"
                >
                  <div className="flex space-x-0.5">
                    {[1, 2, 3, 4, 5].map((i) => (
                      <svg
                        key={"icon" + i}
                        className={
                          i <= review.rating
                            ? "text-orange-500 w-5 h-5"
                            : "text-gray-300 w-5 h-5"
                        }
                        fill={i <= review.rating ? "currentColor" : "none"}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"></path>
                      </svg>
                    ))}
                  </div>
                  <p className="mt-2 text-sm font-medium leading-5 text-gray-500">
                    {review.date.substr(0, 10)}
                  </p>
                  <div className="space-y-1 mt-2">
                    <h3 className="font-semibold text-gray-800">
                      {review.title}
                    </h3>
                    <p className="review_body text-sm font-medium leading-5 text-gray-600">
                      {review.review}
                    </p>
                  </div>
                  <div className="mt-6 flex items-center space-x-2">
                    <span className="text-sm font-semibold leading-5 text-gray-900">
                      {review.name}
                    </span>
                  </div>
                </div>
              ))}
              items_per_page={isMobile ? 1 : 2}
              item_class={".review"}
              dynamic_height={true}
            ></ProductCarousel>
          </Fragment>
        ) : null}
      </section>
      {product.banners?.map((banner, i) => (
        <div
          className={(i % 2 == 0 ? "even" : "odd") + " product_banner"}
          key={"banner" + i}
        >
          <img src={banner.image}></img>
          {banner.heading ? (
            <div
              className={
                i % 2 === 0
                  ? "py-10 pl-5 sm:pl-14 pr-5 sm:pr-20 2xl:pr-32 col"
                  : "py-10 pr-14 pl-5 sm:pl-20 2xl:pl-32 col"
              }
            >
              {/* <h1 data-scroll data-scroll-speed="0.5" className="heading mb-5"> */}
              <h1 className="heading mb-5">{banner.heading}</h1>
              {/* <p data-scroll data-scroll-speed="0.5"> */}
              <p>{banner.copy}</p>
            </div>
          ) : null}
        </div>
      ))}
      <section className="px-5 sm:px-20 2xl:px-32 mt-20">
        <h1 className="text-center py-5 heading h2">Specifications</h1>
        {specifications.map((specification) => (
          <Collapse header={specification.name} key={specification.key}>
            <div className="p-5">
              {product[specification.key]
                ? typeof product[specification.key] == "object"
                  ? Object.keys(product[specification.key]).map((spec) => (
                      <div className="row py-2" key={spec}>
                        <p>{specsKeyMapper[spec]}</p>
                        <p>
                          {typeof product[specification.key][spec] == "boolean"
                            ? product[specification.key][spec]
                              ? "Yes"
                              : "No"
                            : product[specification.key][spec]}
                        </p>
                      </div>
                    ))
                  : product[specification.key]
                : null}
            </div>
          </Collapse>
        ))}
      </section>
      {product.faqs?.length > 0 ? (
        <section className="px-5 sm:px-20 2xl:px-32 mt-20">
          <h1 className="text-center py-5 heading h2">FAQ</h1>
          {product.faqs?.map((faq, i) => (
            <Collapse header={faq.question} key={"faq" + i}>
              <div className="p-5">{faq.answer}</div>
            </Collapse>
          ))}
        </section>
      ) : null}

      <section className="px-5 sm:px-20 2xl:px-32 mt-20">
        <div className="seperator mt-20"></div>
        <h1 className="text-center py-5 heading h2">Recommended Products</h1>
        <ProductCarousel
          items={allProducts.map((p) =>
            p.id !== product.id ? (
              <ProductCard product={p} key={"product" + p.id}></ProductCard>
            ) : null
          )}
          items_per_page={3}
          item_class={".product_card"}
          dynamic_height={false}
        ></ProductCarousel>
      </section>
    </Fragment>
  );
}

export default Product;
