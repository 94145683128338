import { useEffect } from "react";
import "./ServiceCenters.scss";
function ServiceCenters() {
  useEffect(() => {
    document
      .querySelectorAll("tbody td:first-child,tbody td:nth-child(2)")
      .forEach((td) => {
        td.innerHTML = td.textContent.toLowerCase();
      });
    document.querySelectorAll("tbody td:last-child").forEach((td) => {
      const numbers = td.textContent.split("/");
      td.innerHTML = "";
      for (let number of numbers) {
        td.innerHTML += `<a href="tel:+${number}">${number}</a>`;
      }
    });
  });
  return (
    <section id="ServiceCenters" className="px-5 md:px-20 pt-20 sm:pt-36">
      <h1 className="heading mb-10 text-center">Service centers</h1>
      <table className="shadow-2xl">
        <thead>
          <tr>
            <td>State</td>
            <td>City </td>
            <td>Contact Number</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Andhra Pradesh</td>
            <td>ANATHAPUR</td>
            <td>9347173118</td>
          </tr>
          <tr>
            <td>Andhra Pradesh</td>
            <td>GUNTUR</td>
            <td>9849810801</td>
          </tr>
          <tr>
            <td>Andhra Pradesh</td>
            <td>HYDERABAD</td>
            <td>8886229966</td>
          </tr>
          <tr>
            <td>Andhra Pradesh</td>
            <td>KADAPA</td>
            <td>9347129454</td>
          </tr>
          <tr>
            <td>Andhra Pradesh</td>
            <td>KAKINADA</td>
            <td>9848651395</td>
          </tr>
          <tr>
            <td>Andhra Pradesh</td>
            <td>KRISHNA</td>
            <td> 92484 73330</td>
          </tr>
          <tr>
            <td>Andhra Pradesh</td>
            <td>KURNOOL</td>
            <td>7799345545</td>
          </tr>
          <tr>
            <td>Andhra Pradesh</td>
            <td>NELLORE</td>
            <td>9866441943</td>
          </tr>
          <tr>
            <td>Andhra Pradesh</td>
            <td>Ongole</td>
            <td>8790938297</td>
          </tr>
          <tr>
            <td>Andhra Pradesh</td>
            <td>TANUKU</td>
            <td>9848239343</td>
          </tr>
          <tr>
            <td>Andhra Pradesh</td>
            <td>TIRUPATHI</td>
            <td>7022806288</td>
          </tr>
          <tr>
            <td>Andhra Pradesh</td>
            <td>VIZAG</td>
            <td>9848339579</td>
          </tr>
          <tr>
            <td>Bihar</td>
            <td>ARRAH</td>
            <td>9955575845</td>
          </tr>
          <tr>
            <td>Bihar</td>
            <td>AURANGABAD</td>
            <td>7979909116</td>
          </tr>
          <tr>
            <td>Bihar</td>
            <td>BHAGALPUR</td>
            <td>9155333414</td>
          </tr>
          <tr>
            <td>Bihar</td>
            <td>BIHARSHARIFF</td>
            <td>9097847720</td>
          </tr>
          <tr>
            <td>Bihar</td>
            <td>BOKARO</td>
            <td>9334909705</td>
          </tr>
          <tr>
            <td>Bihar</td>
            <td>BUXAR</td>
            <td>7488008013</td>
          </tr>
          <tr>
            <td>Bihar</td>
            <td>DANAPUR</td>
            <td>7903495008</td>
          </tr>
          <tr>
            <td>Bihar</td>
            <td>DARBHANGA</td>
            <td>7488448745</td>
          </tr>
          <tr>
            <td>Bihar</td>
            <td>DEHRI ON SONE</td>
            <td>9122442658</td>
          </tr>
          <tr>
            <td>Bihar</td>
            <td>GAYA</td>
            <td>9334808220</td>
          </tr>
          <tr>
            <td>Bihar</td>
            <td>KISHANGANJ</td>
            <td>7677722252</td>
          </tr>
          <tr>
            <td>Bihar</td>
            <td>MUZAFFARPUR</td>
            <td>9386743070</td>
          </tr>
          <tr>
            <td>Bihar</td>
            <td>PATNA</td>
            <td>7903907816</td>
          </tr>
          <tr>
            <td>Bihar</td>
            <td>PATNA</td>
            <td>9546366636</td>
          </tr>
          <tr>
            <td>Bihar</td>
            <td>SITAMARHI</td>
            <td>8340625168</td>
          </tr>
          <tr>
            <td>CHANDIGARH</td>
            <td>CHANDIGARH</td>
            <td>9814016379</td>
          </tr>
          <tr>
            <td>Chhattisgarh</td>
            <td>CHATTISGARH</td>
            <td>9993326569</td>
          </tr>
          <tr>
            <td>Chhattisgarh</td>
            <td>CHATTISGARH</td>
            <td>7415041552</td>
          </tr>
          <tr>
            <td>Chhattisgarh</td>
            <td>Dhamtari</td>
            <td>9907169593 /7770856599</td>
          </tr>
          <tr>
            <td>Chhattisgarh</td>
            <td>Morba</td>
            <td>9826662063</td>
          </tr>
          <tr>
            <td>Chhattisgarh</td>
            <td>Raipur</td>
            <td>9300130045 /8982130045</td>
          </tr>
          <tr>
            <td>Chhattisgarh</td>
            <td>Rajnandgaon</td>
            <td>9770194615 /8770666544</td>
          </tr>
          <tr>
            <td>Chhattisgarh</td>
            <td>Surguja</td>
            <td>9754286762</td>
          </tr>
          <tr>
            <td>Chhattisgarh</td>
            <td>Talpara</td>
            <td>9300321526/9755150500</td>
          </tr>
          <tr>
            <td>DELHI</td>
            <td>DELHI</td>
            <td>8802362181</td>
          </tr>
          <tr>
            <td>DELHI</td>
            <td>NEW DELHI</td>
            <td>8587088567</td>
          </tr>
          <tr>
            <td>DELHI</td>
            <td>NEW DELHI</td>
            <td>8745054294</td>
          </tr>
          <tr>
            <td>DELHI</td>
            <td>NEW DELHI</td>
            <td>9213996900</td>
          </tr>
          <tr>
            <td>DELHI</td>
            <td>NEW DELHI</td>
            <td>9811679414</td>
          </tr>
          <tr>
            <td>DELHI</td>
            <td>NEW DELHI</td>
            <td>9911132199</td>
          </tr>
          <tr>
            <td>GOA</td>
            <td>GOA</td>
            <td>9970997563</td>
          </tr>
          <tr>
            <td>Goa</td>
            <td>Goa</td>
            <td>9579126665</td>
          </tr>
          <tr>
            <td>Gujarat</td>
            <td>Ahmedabad</td>
            <td>9909998090</td>
          </tr>
          <tr>
            <td>Gujarat</td>
            <td>Ahmedabad</td>
            <td>9825406134</td>
          </tr>
          <tr>
            <td>Gujarat</td>
            <td>Anand</td>
            <td>9824640005</td>
          </tr>
          <tr>
            <td>Gujarat</td>
            <td>Bharuch</td>
            <td>9904758764</td>
          </tr>
          <tr>
            <td>Gujarat</td>
            <td>Bhavnagar</td>
            <td>9824452224</td>
          </tr>
          <tr>
            <td>Gujarat</td>
            <td>Dahod</td>
            <td>9428544442</td>
          </tr>
          <tr>
            <td>Gujarat</td>
            <td>Gandhinagar</td>
            <td>9879976916</td>
          </tr>
          <tr>
            <td>Gujarat</td>
            <td>GUJARAT</td>
            <td>9909917471</td>
          </tr>
          <tr>
            <td>GUJARAT</td>
            <td>GUJARAT</td>
            <td>9825727484</td>
          </tr>
          <tr>
            <td>GUJARAT</td>
            <td>GUJARAT</td>
            <td>9924181200</td>
          </tr>
          <tr>
            <td>GUJARAT</td>
            <td>GUJARAT</td>
            <td>9879461845</td>
          </tr>
          <tr>
            <td>Gujarat</td>
            <td>GUJARAT</td>
            <td>9664545955</td>
          </tr>
          <tr>
            <td>Gujarat</td>
            <td>Junagadh</td>
            <td>9879009093</td>
          </tr>
          <tr>
            <td>Gujarat</td>
            <td>Kalol</td>
            <td>9904159053</td>
          </tr>
          <tr>
            <td>GUJARAT</td>
            <td>Kutch</td>
            <td>94264 52751</td>
          </tr>
          <tr>
            <td>Gujarat</td>
            <td>Mahesana</td>
            <td>9825897335</td>
          </tr>
          <tr>
            <td>Gujarat</td>
            <td>Morbi</td>
            <td>9925452505</td>
          </tr>
          <tr>
            <td>Gujarat</td>
            <td>Navsari</td>
            <td>7567418748</td>
          </tr>
          <tr>
            <td>Gujarat</td>
            <td>Patan</td>
            <td>9427085569</td>
          </tr>
          <tr>
            <td>Gujarat</td>
            <td>Porbandar</td>
            <td>9687383038</td>
          </tr>
          <tr>
            <td>Gujarat</td>
            <td>Rajkot</td>
            <td>9512505422</td>
          </tr>
          <tr>
            <td>Gujarat</td>
            <td>Surat</td>
            <td>93750 05661/97272 26822</td>
          </tr>
          <tr>
            <td>Gujarat</td>
            <td>Surendranagar</td>
            <td>8306925005</td>
          </tr>
          <tr>
            <td>Gujarat</td>
            <td>Vadodara</td>
            <td>9904071559</td>
          </tr>
          <tr>
            <td>HARYANA</td>
            <td>AMBALA</td>
            <td>8199945070</td>
          </tr>
          <tr>
            <td>HARYANA</td>
            <td>BHIWANI</td>
            <td>9518283000</td>
          </tr>
          <tr>
            <td>HARYANA</td>
            <td>FARIDABAD</td>
            <td>9212012728</td>
          </tr>
          <tr>
            <td>HARYANA</td>
            <td>FATEHABAD</td>
            <td>8923300029</td>
          </tr>
          <tr>
            <td>HARYANA</td>
            <td>GURUGRAM</td>
            <td>9315430706</td>
          </tr>
          <tr>
            <td>HARYANA</td>
            <td>GURUGRAM</td>
            <td>9540207999</td>
          </tr>
          <tr>
            <td>HARYANA</td>
            <td>HISSAR</td>
            <td>8199940300</td>
          </tr>
          <tr>
            <td>HARYANA</td>
            <td>JIND</td>
            <td>7206010400</td>
          </tr>
          <tr>
            <td>HARYANA</td>
            <td>KAITHAL</td>
            <td>9729347746</td>
          </tr>
          <tr>
            <td>HARYANA</td>
            <td>KARNAL</td>
            <td>9729347746</td>
          </tr>
          <tr>
            <td>HARYANA</td>
            <td>KURUKSHETRA</td>
            <td>9466796370</td>
          </tr>
          <tr>
            <td>HARYANA</td>
            <td>MAHENDERGARH</td>
            <td>9466382144</td>
          </tr>
          <tr>
            <td>HARYANA</td>
            <td>NARNAUL</td>
            <td>9416115003</td>
          </tr>
          <tr>
            <td>HARYANA</td>
            <td>PALWAL</td>
            <td>7017160807</td>
          </tr>
          <tr>
            <td>HARYANA</td>
            <td>PANIPAT</td>
            <td>9802700027</td>
          </tr>
          <tr>
            <td>HARYANA</td>
            <td>REWARI</td>
            <td>9053000152</td>
          </tr>
          <tr>
            <td>HARYANA</td>
            <td>ROHTAK</td>
            <td>9416518357</td>
          </tr>
          <tr>
            <td>HARYANA</td>
            <td>ROHTAK</td>
            <td>7988804257</td>
          </tr>
          <tr>
            <td>HARYANA</td>
            <td>SIRSA</td>
            <td>9812212657</td>
          </tr>
          <tr>
            <td>HARYANA</td>
            <td>SONIPAT</td>
            <td>9215252422</td>
          </tr>
          <tr>
            <td>HIMACHAL PRADESH</td>
            <td>BILASPUR</td>
            <td>8544701161</td>
          </tr>
          <tr>
            <td>HIMACHAL PRADESH</td>
            <td>CHAMBA</td>
            <td>7018031933</td>
          </tr>
          <tr>
            <td>HIMACHAL PRADESH</td>
            <td>DHARAMSHALA</td>
            <td>8219746620</td>
          </tr>
          <tr>
            <td>HIMACHAL PRADESH</td>
            <td>KANGRA</td>
            <td>9418184277</td>
          </tr>
          <tr>
            <td>HIMACHAL PRADESH</td>
            <td>MANDI</td>
            <td>9418056651</td>
          </tr>
          <tr>
            <td>HIMACHAL PRADESH</td>
            <td>PAONTA SAHIB</td>
            <td>7876000973</td>
          </tr>
          <tr>
            <td>HIMACHAL PRADESH</td>
            <td>SHIMLA</td>
            <td>9816258777</td>
          </tr>
          <tr>
            <td>HIMACHAL PRADESH</td>
            <td>UNA</td>
            <td>9805977100</td>
          </tr>
          <tr>
            <td>JAMMU &amp; KASHMIR</td>
            <td>JAMMU</td>
            <td>7889742897</td>
          </tr>
          <tr>
            <td>JAMMU &amp; KASHMIR</td>
            <td>JAMMU</td>
            <td>9419214278</td>
          </tr>
          <tr>
            <td>JAMMU &amp; KASHMIR</td>
            <td>SRINAGAR</td>
            <td>9622444115</td>
          </tr>
          <tr>
            <td>Jarkhand</td>
            <td>JAMSHEDPUR</td>
            <td>7903275896</td>
          </tr>
          <tr>
            <td>Jarkhand</td>
            <td>RANCHI</td>
            <td>8789444958</td>
          </tr>
          <tr>
            <td>Karnataka</td>
            <td>BANGALORE</td>
            <td>8494915208 /9886818612</td>
          </tr>
          <tr>
            <td>Karnataka</td>
            <td>BANGALORE</td>
            <td>9611400455</td>
          </tr>
          <tr>
            <td>Karnataka</td>
            <td>BANGALORE</td>
            <td>9845651008</td>
          </tr>
          <tr>
            <td>Karnataka</td>
            <td>BANGALORE</td>
            <td>9620186727</td>
          </tr>
          <tr>
            <td>Karnataka</td>
            <td>BELGAUM</td>
            <td>9880495018</td>
          </tr>
          <tr>
            <td>Karnataka</td>
            <td>BELLARY</td>
            <td>9916462396 /9964559995</td>
          </tr>
          <tr>
            <td>Karnataka</td>
            <td>CHIKKAMAGALURU</td>
            <td>9008497784</td>
          </tr>
          <tr>
            <td>Karnataka</td>
            <td>DAVANGERE</td>
            <td>7483332201</td>
          </tr>
          <tr>
            <td>Karnataka</td>
            <td>HASSAN</td>
            <td>90088 39809/6363821 645</td>
          </tr>
          <tr>
            <td>Karnataka</td>
            <td>HOSPET</td>
            <td>7026671400</td>
          </tr>
          <tr>
            <td>Karnataka</td>
            <td>HUBLI</td>
            <td>9743277364</td>
          </tr>
          <tr>
            <td>Karnataka</td>
            <td>KALABURAGI</td>
            <td>9972608190 /9916718181</td>
          </tr>
          <tr>
            <td>Karnataka</td>
            <td>KOLAR</td>
            <td>9448005819</td>
          </tr>
          <tr>
            <td>Karnataka</td>
            <td>KUMTA</td>
            <td>9482384722</td>
          </tr>
          <tr>
            <td>Karnataka</td>
            <td>MANDYA</td>
            <td>9964040721</td>
          </tr>
          <tr>
            <td>Karnataka</td>
            <td>MANGLORE</td>
            <td>9844356510 /8242448389</td>
          </tr>
          <tr>
            <td>Karnataka</td>
            <td>MYSORE</td>
            <td>9035151828</td>
          </tr>
          <tr>
            <td>Karnataka</td>
            <td>SHIMOGA</td>
            <td>9448329818 /7353300230</td>
          </tr>
          <tr>
            <td>Karnataka</td>
            <td>TUMKUR</td>
            <td>9880396548</td>
          </tr>
          <tr>
            <td>Karnataka</td>
            <td>UDUPI</td>
            <td>9886618342</td>
          </tr>
          <tr>
            <td>Kerala </td>
            <td>ALAPPUZHA </td>
            <td>9645006526</td>
          </tr>
          <tr>
            <td>Kerala </td>
            <td>CALICUT</td>
            <td>7025801133</td>
          </tr>
          <tr>
            <td>Kerala </td>
            <td>ERNAKULAM</td>
            <td>9349848419</td>
          </tr>
          <tr>
            <td>Kerala </td>
            <td>ERNAKULAM</td>
            <td>9645006501</td>
          </tr>
          <tr>
            <td>Kerala </td>
            <td>kannoor</td>
            <td>8086398301</td>
          </tr>
          <tr>
            <td>Kerala </td>
            <td>KASARGODE</td>
            <td>7907862418</td>
          </tr>
          <tr>
            <td>Kerala </td>
            <td>KOLLAM</td>
            <td>9947805441</td>
          </tr>
          <tr>
            <td>Kerala </td>
            <td>kozhikkkode</td>
            <td>9072666576</td>
          </tr>
          <tr>
            <td>Kerala </td>
            <td>MALAPPURAM</td>
            <td>9656218128</td>
          </tr>
          <tr>
            <td>Kerala </td>
            <td>PALAKKAD </td>
            <td>9744149445</td>
          </tr>
          <tr>
            <td>Kerala </td>
            <td>PATHANANMITHITTA/KOTTAYAM</td>
            <td>9645650500</td>
          </tr>
          <tr>
            <td>Kerala </td>
            <td>Thrissur </td>
            <td>9048736080</td>
          </tr>
          <tr>
            <td>Kerala </td>
            <td>TRIVANDRUM</td>
            <td>9048961106</td>
          </tr>
          <tr>
            <td>Kerala </td>
            <td>VATAKARA</td>
            <td>919995239993/90617 60046</td>
          </tr>
          <tr>
            <td>Kerala </td>
            <td>WAYANAD</td>
            <td>7025330995/9747431530</td>
          </tr>
          <tr>
            <td>Madhya Pradesh</td>
            <td>Bharuch</td>
            <td>9826411120</td>
          </tr>
          <tr>
            <td>Madhya Pradesh</td>
            <td>Bharuch</td>
            <td>9826358164 /9893086399</td>
          </tr>
          <tr>
            <td>Madhya Pradesh</td>
            <td>Bhopal</td>
            <td>9826494433 /9752490580</td>
          </tr>
          <tr>
            <td>Madhya Pradesh</td>
            <td>Burhanpur</td>
            <td>9691688390 /8966988390 /9039132415</td>
          </tr>
          <tr>
            <td>Madhya Pradesh</td>
            <td>Bwarka</td>
            <td>9179935527/9300278427</td>
          </tr>
          <tr>
            <td>Madhya Pradesh</td>
            <td>Durg</td>
            <td>7067689096/8516942100</td>
          </tr>
          <tr>
            <td>Madhya Pradesh</td>
            <td>indore</td>
            <td>9203809051 /8770536598</td>
          </tr>
          <tr>
            <td>Madhya Pradesh</td>
            <td>indore</td>
            <td>9893908869</td>
          </tr>
          <tr>
            <td>Madhya Pradesh</td>
            <td>indore</td>
            <td>7314029010 /7582898398</td>
          </tr>
          <tr>
            <td>Madhya Pradesh</td>
            <td>Indore</td>
            <td>9826900801</td>
          </tr>
          <tr>
            <td>Madhya Pradesh</td>
            <td>Kalol</td>
            <td>9685489009 /9424685263</td>
          </tr>
          <tr>
            <td>Madhya Pradesh</td>
            <td>MADHYA PRADESH</td>
            <td>8319688694</td>
          </tr>
          <tr>
            <td>Madhya Pradesh</td>
            <td>Neemuch</td>
            <td>9926436210</td>
          </tr>
          <tr>
            <td>Madhya Pradesh</td>
            <td>Neemuch</td>
            <td>9425328008</td>
          </tr>
          <tr>
            <td>Madhya Pradesh</td>
            <td>Raigarh</td>
            <td>9329569920</td>
          </tr>
          <tr>
            <td>Madhya Pradesh</td>
            <td>Raigarh</td>
            <td>9826685512 /9669935580</td>
          </tr>
          <tr>
            <td>Madhya Pradesh</td>
            <td>Ratlam</td>
            <td>9977167291</td>
          </tr>
          <tr>
            <td>Madhya Pradesh</td>
            <td>satna</td>
            <td>7067509956/8889990898</td>
          </tr>
          <tr>
            <td>Madhya Pradesh</td>
            <td>Sehore</td>
            <td>9977274075</td>
          </tr>
          <tr>
            <td>Madhya Pradesh</td>
            <td>Shivpuri</td>
            <td>9755593900</td>
          </tr>
          <tr>
            <td>Madhya Pradesh</td>
            <td>Singrauli</td>
            <td>9300009817 /9302664645</td>
          </tr>
          <tr>
            <td>Madhya Pradesh</td>
            <td>Singrauli</td>
            <td>7610138113</td>
          </tr>
          <tr>
            <td>Madhya Pradesh</td>
            <td>Talpara</td>
            <td>9300500444</td>
          </tr>
          <tr>
            <td>Madhya Pradesh</td>
            <td>Ujjain</td>
            <td>9407842940</td>
          </tr>
          <tr>
            <td>Maharashtra</td>
            <td>Ahmednagar</td>
            <td>9225761955</td>
          </tr>
          <tr>
            <td>Maharashtra</td>
            <td>Akola</td>
            <td>9850315515</td>
          </tr>
          <tr>
            <td>Maharashtra</td>
            <td>AKOLA</td>
            <td>9765285777</td>
          </tr>
          <tr>
            <td>Maharashtra</td>
            <td>Andheri</td>
            <td>8879994320</td>
          </tr>
          <tr>
            <td>Maharashtra</td>
            <td>Aurangabad</td>
            <td>9975111947</td>
          </tr>
          <tr>
            <td>Maharashtra</td>
            <td>Bhandara</td>
            <td>9850307408</td>
          </tr>
          <tr>
            <td>Maharashtra</td>
            <td>Buldhana</td>
            <td>9307805004</td>
          </tr>
          <tr>
            <td>Maharashtra</td>
            <td>CHANDRAPUR</td>
            <td>9021476725</td>
          </tr>
          <tr>
            <td>Maharashtra</td>
            <td>Dhule</td>
            <td>9404889128</td>
          </tr>
          <tr>
            <td>Maharashtra</td>
            <td>Gondia</td>
            <td>8830710633</td>
          </tr>
          <tr>
            <td>Maharashtra</td>
            <td>Ichalkaranji</td>
            <td>9923423735</td>
          </tr>
          <tr>
            <td>Maharashtra</td>
            <td>Jalgaon</td>
            <td>8888882136</td>
          </tr>
          <tr>
            <td>Maharashtra</td>
            <td>Kolhapur</td>
            <td>9822657819</td>
          </tr>
          <tr>
            <td>Maharashtra</td>
            <td>KOLHAPUR</td>
            <td>7721011063</td>
          </tr>
          <tr>
            <td>Maharashtra</td>
            <td>Latur</td>
            <td>9665161165</td>
          </tr>
          <tr>
            <td>Maharashtra</td>
            <td>Malad</td>
            <td>7700065391</td>
          </tr>
          <tr>
            <td>Maharashtra</td>
            <td>Nagpur</td>
          </tr>
          <tr>
            <td>Maharashtra</td>
            <td>Nallasopra</td>
            <td>9323934121</td>
          </tr>
          <tr>
            <td>Maharashtra</td>
            <td>Nanded</td>
            <td>7620558192</td>
          </tr>
          <tr>
            <td>Maharashtra</td>
            <td>Nasik</td>
            <td>8262038005</td>
          </tr>
          <tr>
            <td>Maharashtra</td>
            <td>Osmanabad</td>
            <td>9881301989</td>
          </tr>
          <tr>
            <td>Maharashtra</td>
            <td>Panvel</td>
            <td>9702976393</td>
          </tr>
          <tr>
            <td>Maharashtra</td>
            <td>Pune</td>
            <td>8879994320</td>
          </tr>
          <tr>
            <td>Maharashtra</td>
            <td>Solapur</td>
            <td>9850694764</td>
          </tr>
          <tr>
            <td>Maharashtra</td>
            <td>Thane</td>
            <td>7021757716</td>
          </tr>
          <tr>
            <td>Maharashtra</td>
            <td>Washim</td>
            <td>9763760439</td>
          </tr>
          <tr>
            <td>Maharashtra</td>
            <td>Yavatmal</td>
            <td>9960995111</td>
          </tr>
          <tr>
            <td>PUNJAB</td>
            <td>AMRITSAR</td>
            <td>8847380364</td>
          </tr>
          <tr>
            <td>PUNJAB</td>
            <td>BARNALA</td>
            <td>9417238044</td>
          </tr>
          <tr>
            <td>PUNJAB</td>
            <td>BATHINDA</td>
            <td>9417804209</td>
          </tr>
          <tr>
            <td>PUNJAB</td>
            <td>FIROZPUR</td>
            <td>9855565246</td>
          </tr>
          <tr>
            <td>PUNJAB</td>
            <td>FIROZPUR</td>
            <td>9872134005</td>
          </tr>
          <tr>
            <td>PUNJAB</td>
            <td>GURDASPUR</td>
            <td>8968818964</td>
          </tr>
          <tr>
            <td>PUNJAB</td>
            <td>HOSHIARPUR</td>
            <td>9815488998</td>
          </tr>
          <tr>
            <td>PUNJAB</td>
            <td>JALANDHAR</td>
            <td>9855490999</td>
          </tr>
          <tr>
            <td>PUNJAB</td>
            <td>KHANNA</td>
            <td>9855515135</td>
          </tr>
          <tr>
            <td>PUNJAB</td>
            <td>LUDHIANA</td>
            <td>8872012209</td>
          </tr>
          <tr>
            <td>PUNJAB</td>
            <td>MANSA</td>
            <td>7973514100</td>
          </tr>
          <tr>
            <td>PUNJAB</td>
            <td>MOGA</td>
            <td>9781422872</td>
          </tr>
          <tr>
            <td>PUNJAB</td>
            <td>PATHANKOT</td>
            <td>9417420486</td>
          </tr>
          <tr>
            <td>PUNJAB</td>
            <td>PATIALA</td>
            <td>9815143468</td>
          </tr>
          <tr>
            <td>PUNJAB</td>
            <td>ROPAR</td>
            <td>9041164595</td>
          </tr>
          <tr>
            <td>PUNJAB</td>
            <td>RUPNAGAR</td>
            <td>9417390817</td>
          </tr>
          <tr>
            <td>PUNJAB</td>
            <td>SANGRUR</td>
            <td>9914175200</td>
          </tr>
          <tr>
            <td>PUNJAB</td>
            <td>ZIRAKPUR</td>
            <td>9814736401</td>
          </tr>
          <tr>
            <td>PUNJAB</td>
            <td>ZIRAKPUR</td>
            <td>9888486906</td>
          </tr>
          <tr>
            <td>RAJASTHAN</td>
            <td>AJMER</td>
            <td>9352214113</td>
          </tr>
          <tr>
            <td>RAJASTHAN</td>
            <td>ALWAR</td>
            <td>9928676377</td>
          </tr>
          <tr>
            <td>RAJASTHAN</td>
            <td>ALWAR</td>
            <td>9462237806</td>
          </tr>
          <tr>
            <td>RAJASTHAN</td>
            <td>BARMER</td>
            <td>9414493192</td>
          </tr>
          <tr>
            <td>RAJASTHAN</td>
            <td>BHARATPUR</td>
            <td>9414224704</td>
          </tr>
          <tr>
            <td>RAJASTHAN</td>
            <td>BHILWARA</td>
            <td>7387777838</td>
          </tr>
          <tr>
            <td>RAJASTHAN</td>
            <td>BIKANER</td>
            <td>9414283663</td>
          </tr>
          <tr>
            <td>RAJASTHAN</td>
            <td>CHITTORGHAR</td>
            <td>8989621891</td>
          </tr>
          <tr>
            <td>RAJASTHAN</td>
            <td>DAUSA</td>
            <td>9782071617</td>
          </tr>
          <tr>
            <td>RAJASTHAN</td>
            <td>HANUMANGARH</td>
            <td>8058870001</td>
          </tr>
          <tr>
            <td>RAJASTHAN</td>
            <td>JAIPUR</td>
            <td>9214013179</td>
          </tr>
          <tr>
            <td>RAJASTHAN</td>
            <td>JHUJHUNU</td>
            <td>7597999995</td>
          </tr>
          <tr>
            <td>RAJASTHAN</td>
            <td>JODHPUR</td>
            <td>9982247625</td>
          </tr>
          <tr>
            <td>RAJASTHAN</td>
            <td>KOTA</td>
            <td>9929091883</td>
          </tr>
          <tr>
            <td>RAJASTHAN</td>
            <td>KOTA</td>
            <td>9887272839</td>
          </tr>
          <tr>
            <td>RAJASTHAN</td>
            <td>KOTPUTLI</td>
            <td>8949543859</td>
          </tr>
          <tr>
            <td>RAJASTHAN</td>
            <td>NAGAUR</td>
            <td>9982951848</td>
          </tr>
          <tr>
            <td>RAJASTHAN</td>
            <td>PALI</td>
            <td>7877363300</td>
          </tr>
          <tr>
            <td>RAJASTHAN</td>
            <td>SIKAR</td>
            <td>9783100999</td>
          </tr>
          <tr>
            <td>RAJASTHAN</td>
            <td>SRIGANGANAGAR</td>
            <td>8058870001</td>
          </tr>
          <tr>
            <td>RAJASTHAN</td>
            <td>UDAIPUR</td>
            <td>9829800427</td>
          </tr>
          <tr>
            <td>Tamilnadu</td>
            <td>Ariyalur</td>
            <td>9698751568</td>
          </tr>
          <tr>
            <td>Tamilnadu</td>
            <td>Chengalpet</td>
            <td>9962987229</td>
          </tr>
          <tr>
            <td>Tamilnadu</td>
            <td>Chennai</td>
            <td>9092846917</td>
          </tr>
          <tr>
            <td>Tamilnadu</td>
            <td>Chennai</td>
            <td>8939807898</td>
          </tr>
          <tr>
            <td>Tamilnadu</td>
            <td>Chennai</td>
            <td>9123544412</td>
          </tr>
          <tr>
            <td>Tamilnadu</td>
            <td>Chennai</td>
            <td>9940128558</td>
          </tr>
          <tr>
            <td>Tamilnadu</td>
            <td>Chennai</td>
            <td>9363512741</td>
          </tr>
          <tr>
            <td>Tamilnadu</td>
            <td>Coimbatore</td>
            <td>9500655600</td>
          </tr>
          <tr>
            <td>Tamilnadu</td>
            <td>Cuddalore</td>
            <td>995265992</td>
          </tr>
          <tr>
            <td>Tamilnadu</td>
            <td>Dindugal</td>
            <td>9842190314</td>
          </tr>
          <tr>
            <td>Tamilnadu</td>
            <td>Karur</td>
            <td>9500760176</td>
          </tr>
          <tr>
            <td>Tamilnadu</td>
            <td>Kumbakonam</td>
            <td>9025659564</td>
          </tr>
          <tr>
            <td>Tamilnadu</td>
            <td>Madurai</td>
            <td>7010333265</td>
          </tr>
          <tr>
            <td>Tamilnadu</td>
            <td>Nagercoil</td>
            <td>8838791531</td>
          </tr>
          <tr>
            <td>Tamilnadu</td>
            <td>Namakkal</td>
            <td>6374204452</td>
          </tr>
          <tr>
            <td>Tamilnadu</td>
            <td>Pudukotai</td>
            <td>9788399589</td>
          </tr>
          <tr>
            <td>Tamilnadu</td>
            <td>Ramanathapuram</td>
            <td>9943378463</td>
          </tr>
          <tr>
            <td>Tamilnadu</td>
            <td>Salem</td>
            <td>9786270005</td>
          </tr>
          <tr>
            <td>Tamilnadu</td>
            <td>Tambaram</td>
            <td>9677168649</td>
          </tr>
          <tr>
            <td>Tamilnadu</td>
            <td>Thanjavur</td>
            <td>9500867656</td>
          </tr>
          <tr>
            <td>Tamilnadu</td>
            <td>Theni</td>
            <td>9952814036</td>
          </tr>
          <tr>
            <td>Tamilnadu</td>
            <td>Tirunelveli</td>
            <td>9363360066</td>
          </tr>
          <tr>
            <td>Tamilnadu</td>
            <td>Tiruppur</td>
            <td>6369231184</td>
          </tr>
          <tr>
            <td>Tamilnadu</td>
            <td>Trichy</td>
            <td>8220698887</td>
          </tr>
          <tr>
            <td>Tamilnadu</td>
            <td>Vellore</td>
            <td>6384446788</td>
          </tr>
          <tr>
            <td>Tamilnadu</td>
            <td>Villupuram</td>
            <td>9942908565</td>
          </tr>
          <tr>
            <td>Telangana</td>
            <td>HANAMKONDA</td>
            <td>9849262806</td>
          </tr>
          <tr>
            <td>Telangana</td>
            <td>HYDERABAD</td>
            <td>9392023441</td>
          </tr>
          <tr>
            <td>Telangana</td>
            <td>HYDERABAD</td>
            <td>9849664147</td>
          </tr>
          <tr>
            <td>Telangana</td>
            <td>KARIMNAGAR</td>
            <td>9347173222</td>
          </tr>
          <tr>
            <td>Telangana</td>
            <td>khammam</td>
            <td>8555987644</td>
          </tr>
          <tr>
            <td>Telangana</td>
            <td>MAHABOOBNAGAR</td>
            <td>9030430727</td>
          </tr>
          <tr>
            <td>Telangana</td>
            <td>MANCHERIAL</td>
            <td>9652960686</td>
          </tr>
          <tr>
            <td>Telangana</td>
            <td>NALGONDA</td>
            <td>9440405114</td>
          </tr>
          <tr>
            <td>UTTAR PRADESH</td>
            <td>Akbarpur</td>
            <td>8707630784</td>
          </tr>
          <tr>
            <td>UTTAR PRADESH</td>
            <td>BARABANKI</td>
            <td>7460014002</td>
          </tr>
          <tr>
            <td>UTTAR PRADESH</td>
            <td>Bulandshahr</td>
            <td>8899166536</td>
          </tr>
          <tr>
            <td>UTTAR PRADESH</td>
            <td>Gorakhpur</td>
            <td>7007686844</td>
          </tr>
          <tr>
            <td>UTTAR PRADESH</td>
            <td>HARDOI</td>
            <td>7499020242</td>
          </tr>
          <tr>
            <td>UTTAR PRADESH</td>
            <td>Jhansi</td>
            <td>9889556555</td>
          </tr>
          <tr>
            <td>UTTAR PRADESH</td>
            <td>KANNAUJ</td>
            <td>9839210959</td>
          </tr>
          <tr>
            <td>UTTAR PRADESH</td>
            <td>LUCKNOW</td>
            <td>8528326050</td>
          </tr>
          <tr>
            <td>UTTAR PRADESH</td>
            <td>LUCKNOW</td>
            <td>8090266678</td>
          </tr>
          <tr>
            <td>UTTAR PRADESH</td>
            <td>RAEBARELI</td>
            <td>9838390457</td>
          </tr>
          <tr>
            <td>UTTAR PRADESH</td>
            <td>Sultanpur</td>
            <td>8382869495/8354869508</td>
          </tr>
          <tr>
            <td>UTTAR PRADESH (EAST)</td>
            <td>ALLAHABAD</td>
            <td>9889244226</td>
          </tr>
          <tr>
            <td>UTTAR PRADESH (EAST)</td>
            <td>AZAMGARH</td>
            <td>9794990013</td>
          </tr>
          <tr>
            <td>UTTAR PRADESH (EAST)</td>
            <td>BAHRAICH</td>
            <td>6393196637</td>
          </tr>
          <tr>
            <td>UTTAR PRADESH (EAST)</td>
            <td>BANDA</td>
            <td>9305489095</td>
          </tr>
          <tr>
            <td>UTTAR PRADESH (EAST)</td>
            <td>BARABANKI</td>
            <td>7007545713</td>
          </tr>
          <tr>
            <td>UTTAR PRADESH (EAST)</td>
            <td>CHANDAULI</td>
            <td>7004503258</td>
          </tr>
          <tr>
            <td>UTTAR PRADESH (EAST)</td>
            <td>CHITRAKOOT</td>
            <td>7081335599</td>
          </tr>
          <tr>
            <td>UTTAR PRADESH (EAST)</td>
            <td>DEORIA</td>
            <td>7068871393</td>
          </tr>
          <tr>
            <td>UTTAR PRADESH (EAST)</td>
            <td>ETAWAH</td>
            <td>8218622001</td>
          </tr>
          <tr>
            <td>UTTAR PRADESH (EAST)</td>
            <td>FAIZABAD</td>
            <td>9305446931</td>
          </tr>
          <tr>
            <td>UTTAR PRADESH (EAST)</td>
            <td>FARRUKHABAD</td>
            <td>9415591726</td>
          </tr>
          <tr>
            <td>UTTAR PRADESH (EAST)</td>
            <td>FATEHPUR</td>
            <td>9125355555</td>
          </tr>
          <tr>
            <td>UTTAR PRADESH (EAST)</td>
            <td>GONDA</td>
            <td>9648860000</td>
          </tr>
          <tr>
            <td>UTTAR PRADESH (EAST)</td>
            <td>GORAKHPUR</td>
            <td>7752934444</td>
          </tr>
          <tr>
            <td>UTTAR PRADESH (EAST)</td>
            <td>HAMIRPUR</td>
            <td>9450262543</td>
          </tr>
          <tr>
            <td>UTTAR PRADESH (EAST)</td>
            <td>HARDOI</td>
            <td>9453018666</td>
          </tr>
          <tr>
            <td>UTTAR PRADESH (EAST)</td>
            <td>JAUNPUR</td>
            <td>9889079100</td>
          </tr>
          <tr>
            <td>UTTAR PRADESH (EAST)</td>
            <td>JHANSI</td>
            <td>8935036255</td>
          </tr>
          <tr>
            <td>UTTAR PRADESH (EAST)</td>
            <td>KANPUR</td>
            <td>6393105702</td>
          </tr>
          <tr>
            <td>UTTAR PRADESH (EAST)</td>
            <td>LAKHIMPUR</td>
            <td>9369429723</td>
          </tr>
          <tr>
            <td>UTTAR PRADESH (EAST)</td>
            <td>LUCKNOW</td>
            <td>9918501179</td>
          </tr>
          <tr>
            <td>UTTAR PRADESH (EAST)</td>
            <td>MAHOBA</td>
            <td>9793044700</td>
          </tr>
          <tr>
            <td>UTTAR PRADESH (EAST)</td>
            <td>MAU</td>
            <td>9455342453</td>
          </tr>
          <tr>
            <td>UTTAR PRADESH (EAST)</td>
            <td>MIZAPUR</td>
            <td>9312668837</td>
          </tr>
          <tr>
            <td>UTTAR PRADESH (EAST)</td>
            <td>PADRAUNA</td>
            <td>8601404111</td>
          </tr>
          <tr>
            <td>UTTAR PRADESH (EAST)</td>
            <td>PRATAPGARH</td>
            <td>9222243111</td>
          </tr>
          <tr>
            <td>UTTAR PRADESH (EAST)</td>
            <td>RAEBARELI</td>
            <td>8726498853</td>
          </tr>
          <tr>
            <td>UTTAR PRADESH (EAST)</td>
            <td>SIDDHARTH NAGAR</td>
            <td>9792012522</td>
          </tr>
          <tr>
            <td>UTTAR PRADESH (EAST)</td>
            <td>SITAPUR</td>
            <td>8090517273</td>
          </tr>
          <tr>
            <td>UTTAR PRADESH (EAST)</td>
            <td>VARANASI</td>
            <td>9795167777</td>
          </tr>
          <tr>
            <td>UTTAR PRADESH (WEST)</td>
            <td>AGRA</td>
            <td>9719200550</td>
          </tr>
          <tr>
            <td>UTTAR PRADESH (WEST)</td>
            <td>AGRA</td>
            <td>9045493972</td>
          </tr>
          <tr>
            <td>UTTAR PRADESH (WEST)</td>
            <td>ALIGARH</td>
            <td>7838900082</td>
          </tr>
          <tr>
            <td>UTTAR PRADESH (WEST)</td>
            <td>AMROHA</td>
            <td>9058560555</td>
          </tr>
          <tr>
            <td>UTTAR PRADESH (WEST)</td>
            <td>BARAUT</td>
            <td>7983592855</td>
          </tr>
          <tr>
            <td>UTTAR PRADESH (WEST)</td>
            <td>BAREILLY</td>
            <td>9412197298</td>
          </tr>
          <tr>
            <td>UTTAR PRADESH (WEST)</td>
            <td>BIJNOR</td>
            <td>9690667726</td>
          </tr>
          <tr>
            <td>UTTAR PRADESH (WEST)</td>
            <td>BUDAUN</td>
            <td>9914904680</td>
          </tr>
          <tr>
            <td>UTTAR PRADESH (WEST)</td>
            <td>FIROZABAD</td>
            <td>9068596989</td>
          </tr>
          <tr>
            <td>UTTAR PRADESH (WEST)</td>
            <td>GHAZIABAD</td>
            <td>9313905425</td>
          </tr>
          <tr>
            <td>UTTAR PRADESH (WEST)</td>
            <td>GHAZIABAD</td>
            <td>8130897130</td>
          </tr>
          <tr>
            <td>UTTAR PRADESH (WEST)</td>
            <td>HAPUR</td>
            <td>9358312016</td>
          </tr>
          <tr>
            <td>UTTAR PRADESH (WEST)</td>
            <td>KASGANJ</td>
            <td>9719093888</td>
          </tr>
          <tr>
            <td>UTTAR PRADESH (WEST)</td>
            <td>MATHURA</td>
            <td>9911810461</td>
          </tr>
          <tr>
            <td>UTTAR PRADESH (WEST)</td>
            <td>MEERUT</td>
            <td>7983137096</td>
          </tr>
          <tr>
            <td>UTTAR PRADESH (WEST)</td>
            <td>MORADABAD</td>
            <td>7500101131</td>
          </tr>
          <tr>
            <td>UTTAR PRADESH (WEST)</td>
            <td>MUZAFFARNAGAR</td>
            <td>9358045751</td>
          </tr>
          <tr>
            <td>UTTAR PRADESH (WEST)</td>
            <td>NAJIBABAD</td>
            <td>7500082659</td>
          </tr>
          <tr>
            <td>UTTAR PRADESH (WEST)</td>
            <td>NOIDA</td>
            <td>9810139272</td>
          </tr>
          <tr>
            <td>UTTAR PRADESH (WEST)</td>
            <td>NOIDA</td>
            <td>9810139272</td>
          </tr>
          <tr>
            <td>UTTAR PRADESH (WEST)</td>
            <td>SAHARANPUR</td>
            <td>9359839253</td>
          </tr>
          <tr>
            <td>UTTAR PRADESH (WEST)</td>
            <td>SAMBHAL</td>
            <td>9927054130</td>
          </tr>
          <tr>
            <td>UTTAR PRADESH (WEST)</td>
            <td>SHAMLI</td>
            <td>8057566009</td>
          </tr>
          <tr>
            <td>UTTARAKHAND</td>
            <td>DEHRADUN</td>
            <td>8272072091</td>
          </tr>
          <tr>
            <td>UTTARAKHAND</td>
            <td>GARHWAL</td>
            <td>8445884244</td>
          </tr>
          <tr>
            <td>UTTARAKHAND</td>
            <td>HALDWANI</td>
            <td>9837005991</td>
          </tr>
          <tr>
            <td>UTTARAKHAND</td>
            <td>HARIDWAR</td>
            <td>9837862590</td>
          </tr>
          <tr>
            <td>UTTARAKHAND</td>
            <td>RISHIKESH</td>
            <td>9837134567</td>
          </tr>
          <tr>
            <td>UTTARAKHAND</td>
            <td>ROORKEE</td>
            <td>9837004029</td>
          </tr>
        </tbody>
      </table>
    </section>
  );
}

export default ServiceCenters;
