import "./ProjectCard.scss";
import { Link } from "react-router-dom";

function ProductCard(props) {
  const product = props.product;
  return (
    <Link to={"/" + product.slug} className="product_card p-5">
      {product.is_new_launch ? (
        <svg
          className="new_launch_badge"
          width="42"
          height="21"
          viewBox="0 0 42 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M34.5456 18.47L41.0082 11.0499C41.1647 10.8824 41.2566 10.6464 41.262 10.3966C41.2673 10.1469 41.186 9.90522 41.0366 9.7287L34.5512 2.26725C34.4072 2.09633 34.2114 2 34.0073 2L0.769676 2C0.344376 2 -0.000183105 2.40805 -0.000183105 2.91171V17.7934C-0.000183105 18.0354 0.0808525 18.2673 0.225185 18.4382C0.369816 18.6091 0.565601 18.7051 0.769676 18.7051L34.03 18.7051C34.2205 18.7051 34.4042 18.6213 34.5456 18.47Z"
            fill="#EB6039"
          />
          <path
            d="M10.916 4.991H12.099V14H10.916L6.158 6.85V14H4.975V4.991H6.158L10.916 12.141V4.991ZM17.0421 14.117C16.0195 14.117 15.1875 13.779 14.5461 13.103C13.9048 12.427 13.5841 11.5343 13.5841 10.425C13.5841 9.31567 13.9091 8.42733 14.5591 7.76C15.2091 7.084 16.0498 6.746 17.0811 6.746C18.1211 6.746 18.9531 7.06667 19.5771 7.708C20.2098 8.34067 20.5261 9.151 20.5261 10.139C20.5261 10.3903 20.5088 10.6243 20.4741 10.841H14.7931C14.8278 11.5517 15.0488 12.115 15.4561 12.531C15.8721 12.9383 16.4008 13.142 17.0421 13.142C17.5275 13.142 17.9348 13.0337 18.2641 12.817C18.6021 12.6003 18.8491 12.3143 19.0051 11.959H20.2791C20.0538 12.583 19.6638 13.0987 19.1091 13.506C18.5631 13.9133 17.8741 14.117 17.0421 14.117ZM19.2911 9.983C19.2825 9.29833 19.0658 8.75233 18.6411 8.345C18.2165 7.929 17.6878 7.721 17.0551 7.721C16.4311 7.721 15.9111 7.92467 15.4951 8.332C15.0791 8.73067 14.8495 9.281 14.8061 9.983H19.2911ZM30.1764 6.863H31.3724L29.1234 14H27.9014L26.2894 8.332L24.6774 14H23.4424L21.1804 6.863H22.4024L24.0534 12.817L25.7304 6.863H26.9524L28.5384 12.83L30.1764 6.863Z"
            fill="white"
          />
        </svg>
      ) : null}

      <img src={product.image} alt="" />
      <h1>{product.name}</h1>
      <div className="row mt-4">
        <p className="model">MODEL : {product.model}</p>
        <p>
          <s>₹{product.mrp}</s>
          <span>₹{product.price}</span>
        </p>
      </div>
    </Link>
  );
}

export default ProductCard;
