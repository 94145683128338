import { useState, useEffect, Fragment } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import ProductCard from "../ProductCard/ProductCard";
import "./Listing.scss";

function Listing(props) {
  const authCtx = props.authCtx;
  const params = useParams();
  const [products, setProducts] = useState([]);
  function getProducts(path) {
    fetch(authCtx.baseUrl + path)
      .then((res) => {
        if (res.status == 404) {
          getProducts("subcategories/" + params.slug);
        }
        return res.json();
      })
      .then((data) => {
        setProducts(data);
      });
  }
  useEffect(() => {
    getProducts(
      params.slug === "new-launches" ? "products/" : "categories/" + params.slug
    );
  }, [params]);
  return (
    <Fragment>
      <Helmet>
        <title>{products.meta_title}</title>
        <meta name="description" content={products.meta_description} />
        <meta name="keywords" content={products.meta_keywords} />
      </Helmet>
      <div className="seperator mt-20"></div>
      <div className="breadcrumb py-2 px-5 sm:px-20 2xl:px-32">
        <Link to="/" className="item">
          Home
        </Link>
        <div className="item">-</div>
        <div className="item current">{products.name}</div>
        {params.slug === "new-launches" ? (
          <div className="item current">New launches</div>
        ) : null}
      </div>
      <div className="seperator mb-10"></div>
      <section className="listing_section sm:mt-20 px-5 sm:px-20 2xl:px-32 mb-48">
        <div className="products_wrapper">
          {products.sub_categories?.map((sub_category) =>
            sub_category.product_set.map((product, i) => (
              <ProductCard key={i} product={product} />
            ))
          )}
          {products.product_set?.map((product, i) => (
            <ProductCard key={i} product={product} />
          ))}
          {params.slug === "new-launches"
            ? Array.isArray(products)
              ? products.map((product, i) =>
                  product.is_new_launch ? (
                    <ProductCard key={i} product={product} />
                  ) : null
                )
              : null
            : null}
        </div>
        {products.sub_categories?.length == 0 ? (
          <div className="coming_soon">
            <h1 className="heading h2">Coming soon</h1>
          </div>
        ) : null}
      </section>
    </Fragment>
  );
}

export default Listing;
