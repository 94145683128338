import React, { useState } from "react";
import { useCookies } from "react-cookie";
import { gsap } from "gsap/all";
import Login from "./components/Login/Login";

const AuthContext = React.createContext({
  token: "",
  userId: "",
  isLoggedIn: false,
  login: (token, expiry, id) => {},
  logout: () => {},
  showLogin: () => {},
  closeLogin: () => {},
  baseUrl: "",
});

export const AuthContextProvider = (props) => {
  const [cookie, setCookie, removeCookie] = useCookies();

  const [user, setUser] = useState({
    token: cookie["auth-token"],
    id: cookie["user-id"],
    expiry: cookie["token-expiry"],
  });

  const userIsLoggedIn =
    !!user.token &&
    new Date().getTime() < new Date(cookie["token-expiry"]).getTime();

  const loginHandler = (token, expiry, id) => {
    setUser({ token, expiry, id });
    setCookie("auth-token", token);
    setCookie("token-expiry", expiry);
    setCookie("user-id", id);
  };

  const logoutHandler = () => {
    setUser({ token: null, expiry: null });
    removeCookie("auth-token");
    removeCookie("token-expiry");
    removeCookie("user-id");
  };

  const showLoginPopup = () => {
    gsap
      .timeline()
      .set("#loginWrapper", {
        display: "block",
      })
      .to("#loginWrapper", {
        y: 0,
        ease: "expo",
      })
      .to(
        "#loginWrapper .card",
        {
          y: 0,
          scale: 1,
          ease: "expo",
        },
        0.1
      );
  };
  const closeLoginPopup = () => {
    gsap
      .timeline()
      .to("#loginWrapper", {
        y: 1000,
        ease: "expo",
      })
      .to(
        "#loginWrapper .card",
        {
          y: 1000,
          scale: 0.8,
          ease: "expo",
        },
        0.1
      )
      .set("#loginWrapper", {
        display: "none",
      });
  };

  const contextValue = {
    token: user.token,
    userId: user.id,
    isLoggedIn: userIsLoggedIn,
    login: loginHandler,
    logout: logoutHandler,
    showLogin: showLoginPopup,
    closeLogin: closeLoginPopup,
    // baseUrl: "http://159.65.147.189/api/",
    baseUrl: "https://www.coocaatv.in/api/",
    // baseUrl: "http://localhost:8000/api/",
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
      {!contextValue.isLoggedIn && (
        <div id="loginWrapper">
          <Login></Login>
          <div className="close" onClick={contextValue.closeLogin}>
            <svg
              width="24"
              height="24"
              xmlns="http://www.w3.org/2000/svg"
              fillRule="evenodd"
              clipRule="evenodd"
            >
              <path d="M12 11.293l10.293-10.293.707.707-10.293 10.293 10.293 10.293-.707.707-10.293-10.293-10.293 10.293-.707-.707 10.293-10.293-10.293-10.293.707-.707 10.293 10.293z" />
            </svg>
          </div>
        </div>
      )}
    </AuthContext.Provider>
  );
};

export default AuthContext;
