import { useEffect, useRef, useState } from "react";
import { gsap } from "gsap/all";

function Collapse(props) {
  const contentWrapper = useRef();
  const [isCollapsed, setIsCollapsed] = useState(true);
  const toggle = () => {
    gsap.to(contentWrapper.current, {
      height: isCollapsed ? "auto" : 0,
      opacity: isCollapsed ? 1 : 0,
      pointerEvents: isCollapsed ? "unset" : "none",
    });
    setIsCollapsed((v) => !v);
  };
  useEffect(() => {
    gsap.to(contentWrapper.current, {
      height: 0,
      opacity: 0,
      pointerEvents: "none",
    });
  }, []);
  return (
    <div className="spec_wrapper mb-0.5">
      <div className="header py-3 px-5 sm:px-14" onClick={toggle}>
        {props.header}
        <div className="icon">
          <div className="line"></div>
          <div className={isCollapsed ? "line ver" : "line"}></div>
        </div>
      </div>
      <div ref={contentWrapper} className="content_wrapper sm:px-14">
        {props.children}
      </div>
    </div>
  );
}

export default Collapse;
