import { Fragment, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./Support.scss";

function Support(props) {
  const authCtx = props.authCtx;
  const form = useRef();
  const [querySent, setQuerySent] = useState(false);

  async function sendQuery(e) {
    e.preventDefault();
    let formData = new FormData(form.current);
    fetch(authCtx.baseUrl + "query/", {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        form.current.reset();
        setQuerySent(true);
      });
  }
  return (
    <section>
      <Helmet>
        <title>Coocaa India | Product Help & Support</title>
        <meta
          name="description"
          content="Welcome to Coocaa Support · Customer care · Service center · Warranty · Spare parts Book hassle-free service at doorstep by expert technicians at affordable rates."
        />
      </Helmet>
      <div className="seperator mt-20"></div>
      <div className="breadcrumb py-2 px-5 sm:px-20 2xl:px-32">
        <Link to="/" className="item">
          Home
        </Link>
        <div className="item">-</div>
        <div className="item current">Support</div>
      </div>
      <div className="seperator mb-10"></div>

      <section id="supportSection" className="px-5 sm:px-20 2xl:px-32">
        <h1 className="heading">Support</h1>
        <hr />
        {querySent ? (
          <Fragment>
            <h1 className="mt-10 text-xl">
              We've received your message. We'll get get back to you soon.
            </h1>
            <button className="p_btn mt-5" onClick={() => setQuerySent(false)}>
              Done
            </button>
          </Fragment>
        ) : (
          <Fragment>
            <div className="main_row mt-10">
              <a href="tel:1800-12344-6666" className="card py-5 sm:py-10">
                <svg
                  width="24"
                  height="24"
                  xmlns="http://www.w3.org/2000/svg"
                  fillRule="evenodd"
                  clipRule="evenodd"
                >
                  <path d="M16 22.621l-3.521-6.795c-.007.004-1.974.97-2.064 1.011-2.24 1.086-6.799-7.82-4.609-8.994l2.082-1.026-3.492-6.817-2.106 1.039c-1.622.845-2.298 2.627-2.289 4.843.027 6.902 6.711 18.013 12.212 18.117.575.011 1.137-.098 1.677-.345.121-.055 2.102-1.029 2.11-1.033zm4-5.621h-1v-13h1v13zm-2-2h-1v-9h1v9zm4-1h-1v-7h1v7zm-6-1h-1v-5h1v5zm-2-1h-1v-3h1v3zm10 0h-1v-3h1v3zm-12-1h-1v-1h1v1z" />
                </svg>
                <h1>Toll free Support</h1>
                <p>1800-12344-6666</p>
              </a>
              <a href="tel:091212 58575" className="card py-5 sm:py-10">
                <svg
                  width="24"
                  height="24"
                  xmlns="http://www.w3.org/2000/svg"
                  fillRule="evenodd"
                  clipRule="evenodd"
                >
                  <path d="M16 22.621l-3.521-6.795c-.007.004-1.974.97-2.064 1.011-2.24 1.086-6.799-7.82-4.609-8.994l2.082-1.026-3.492-6.817-2.106 1.039c-1.622.845-2.298 2.627-2.289 4.843.027 6.902 6.711 18.013 12.212 18.117.575.011 1.137-.098 1.677-.345.121-.055 2.102-1.029 2.11-1.033zm4-5.621h-1v-13h1v13zm-2-2h-1v-9h1v9zm4-1h-1v-7h1v7zm-6-1h-1v-5h1v5zm-2-1h-1v-3h1v3zm10 0h-1v-3h1v3zm-12-1h-1v-1h1v1z" />
                </svg>
                <h1>Sales</h1>
                <p>091212 58575</p>
              </a>
              <a
                href="mailto:contactus@coocaatv.in"
                className="card py-5 sm:py-10"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path d="M12 12.713l-11.985-9.713h23.97l-11.985 9.713zm0 2.574l-12-9.725v15.438h24v-15.438l-12 9.725z" />
                </svg>
                <h1>Mail</h1>
                <p>contactus@coocaatv.in</p>
              </a>
              {/* <div className="card span py-5 sm:py-10">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path d="M12 1c-3.148 0-6 2.553-6 5.702 0 3.148 2.602 6.907 6 12.298 3.398-5.391 6-9.15 6-12.298 0-3.149-2.851-5.702-6-5.702zm0 8c-1.105 0-2-.895-2-2s.895-2 2-2 2 .895 2 2-.895 2-2 2zm8 6h-3.135c-.385.641-.798 1.309-1.232 2h3.131l.5 1h-4.264l-.344.544-.289.456h.558l.858 2h-7.488l.858-2h.479l-.289-.456-.343-.544h-2.042l-1.011-1h2.42c-.435-.691-.848-1.359-1.232-2h-3.135l-4 8h24l-4-8zm-12.794 6h-3.97l1.764-3.528 1.516 1.528h1.549l-.859 2zm8.808-2h3.75l1 2h-3.892l-.858-2z" />
            </svg>
            <h1>Address</h1>
            <p>
              Plot No 470, 1st floor Udyog Vihar Phase V, Gurgaon,Haryana, India
            </p>
          </div> */}
            </div>
            <h1 className="mt-20 mb-10 text-2xl">Send a message</h1>
            <form onSubmit={sendQuery} ref={form}>
              <input
                name="name"
                type="text"
                placeholder="Name"
                maxlength="100"
                aria-label="Name"
                required
              />
              <input
                name="email"
                type="email"
                placeholder="Email"
                required
                pattern="^.+@.+\.[a-zA-Z]{2,63}$"
                maxlength="250"
                aria-label="Email"
              ></input>
              <input
                name="subject"
                type="text"
                placeholder="Subject"
                aria-label="Subject"
                required
              />
              <input
                name="phone"
                type="tel"
                placeholder="Phone"
                pattern="^[+]?([(][0-9]{1,3}[)][-]?)?([0-9][-]?){3,16}[0-9]$"
                maxlength="50"
                required
                aria-label="Phone"
              />
              <textarea
                required
                name="message"
                placeholder="Type your message here..."
              ></textarea>
              <div>
                <button className="p_btn">Submit</button>
              </div>
            </form>
          </Fragment>
        )}
      </section>
    </section>
  );
}

export default Support;
