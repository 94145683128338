import { Fragment, useContext, useState } from "react";
import "./Login.scss";
import AuthContext from "./../../auth-context";

function Login(props) {
  const authCtx = useContext(AuthContext);

  const [activeTab, setActiveTab] = useState(1);

  async function register(e) {
    e.preventDefault();
    let formData = new FormData(document.querySelector("form.signup"));
    fetch(authCtx.baseUrl + "register/", {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.hasOwnProperty("username")) {
          alert("A account with that mobile already exists.");
        } else {
          authCtx.login(data.token, data.expiry, data.user.id);
          authCtx.closeLogin();
        }
      });
  }

  async function login(e) {
    e.preventDefault();
    let formData = new FormData(document.querySelector("form.login"));
    fetch(authCtx.baseUrl + "login/", {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.hasOwnProperty("non_field_errors")) {
          alert(data["non_field_errors"][0]);
          return;
        }
        authCtx.login(data.token, data.expiry, data.user.id);
        authCtx.closeLogin();
      });
  }

  return (
    <div id="login">
      <div className="card">
        <div className="top">
          <button
            className={activeTab == 1 ? "active" : ""}
            onClick={() => setActiveTab(1)}
          >
            Sign-In
          </button>
          <button
            className={activeTab == 2 ? "active" : ""}
            onClick={() => setActiveTab(2)}
          >
            Signup
          </button>
        </div>
        {activeTab == 1 ? (
          <Fragment>
            <p>Login here with your credentials</p>
            <form onSubmit={login} className="login">
              <div className="group">
                <div className="phone_code">+91</div>
                <input
                  type="text"
                  name="username"
                  required
                  placeholder="Phone Number"
                />
              </div>
              <div className="group">
                <input
                  type="password"
                  name="password"
                  required
                  placeholder="Password"
                />
              </div>
              <button className="p_btn" type="submit">
                LOGIN
              </button>
            </form>
          </Fragment>
        ) : (
          <form className="signup" onSubmit={register}>
            <div className="group">
              <label htmlFor="mobile">Mobile Number</label>
              <input type="text" name="username" id="mobile" required />
            </div>
            <div className="group">
              <label htmlFor="password">Password</label>
              <input type="password" name="password" id="password" required />
            </div>
            <div className="group">
              <label htmlFor="confirm_password">Confirm Password</label>
              <input
                type="password"
                name="confirm_password"
                id="confirm_password"
                required
              />
            </div>
            <div className="group">
              <label>Name</label>
              <input type="text" name="name" required />
            </div>
            <div className="group">
              <label htmlFor="emailId">Email id</label>
              <input type="email" name="email" id="emailId" required />
            </div>
            <div className="terms_row">
              <input type="checkbox" name="terms" required />
              <label htmlFor="terms">
                {" "}
                I agree to <a href="">Terms & Conditions</a>
              </label>
            </div>
            <button type="submit" className="p_btn">
              SIGNUP
            </button>
          </form>
        )}
        {activeTab == 1 ? (
          <p>
            Don't have an account?{" "}
            <span onClick={() => setActiveTab(2)}>Register Now</span>
          </p>
        ) : (
          <p>
            Have an account?{" "}
            <span onClick={() => setActiveTab(1)}>Login Now</span>
          </p>
        )}
      </div>
    </div>
  );
}

export default Login;
