import { Fragment, useContext, useRef, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { LocomotiveScrollProvider } from "react-locomotive-scroll";
import "./App.scss";
import AuthContext from "./auth-context";
import { useCart } from "react-use-cart";
import ScrollToTop from "./ScrollToTop";
import Navbar from "./components/Navbar/Navbar";
import Home from "./components/Home/Home";
import Footer from "./components/Footer/Footer";
import Product from "./components/Product/Product";
import Listing from "./components/Listing/Listing";
import Cart from "./components/Cart/Cart";
import Checkout from "./components/Checkout/Checkout";
import Profile from "./components/Profile/Profile";
import ChangePassword from "./components/ChangePassword/ChangePassword";
import Blogs from "./components/Blogs/Blogs";
import Support from "./components/Support/Support";
import PrivacyPolicy from "./components/Policy/PrivacyPolicy";
import WarrantyPolicy from "./components/Policy/WarrantyPolicy";
import Press from "./components/Blogs/Press";
import About from "./components/Policy/About";
import ServiceCenters from "./components/ServiceCenters/ServiceCenters";
import Ewaste from "./components/Ewaste/Ewaste";

function App() {
  const containerRef = useRef(null);
  const authCtx = useContext(AuthContext);
  const { isEmpty } = useCart();
  return (
    <BrowserRouter>
      <Navbar authCtx={authCtx} />
      <LocomotiveScrollProvider
        options={{
          smooth: true,
          getDirection: true,
          // lerp: 0.08,
          touchMultiplier: 3,
          smartphone: {
            smooth: true,
          },
          tablet: {
            smooth: true,
          },
          reloadOnContextChange: true,
        }}
        watch={[]}
        containerRef={containerRef}
      >
        <main data-scroll-container ref={containerRef}>
          <ScrollToTop>
            <Routes>
              <Route index element={<Home authCtx={authCtx} />} />
              <Route
                path="/products/:slug"
                element={<Listing authCtx={authCtx} />}
              />
              <Route path="/blogs" element={<Blogs authCtx={authCtx} />}>
                <Route path=":blogId"></Route>
              </Route>
              <Route path="/press" element={<Press authCtx={authCtx} />} />
              <Route path="/cart" element={<Cart authCtx={authCtx} />} />
              <Route path="/support" element={<Support authCtx={authCtx} />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/warranty-policy" element={<WarrantyPolicy />} />
              <Route path="/about" element={<About />} />
              <Route path="/service-centers" element={<ServiceCenters />} />
              <Route path="/e-waste" element={<Ewaste />} />
              {!isEmpty && <Route path="/checkout" element={<Checkout />} />}
              {authCtx.isLoggedIn && (
                <Fragment>
                  <Route path="/profile" element={<Profile />} />
                  <Route
                    path="/change-password"
                    element={<ChangePassword />}
                  ></Route>
                </Fragment>
              )}
              <Route path=":slug" element={<Product authCtx={authCtx} />} />
            </Routes>
          </ScrollToTop>
          <Footer authCtx={authCtx}></Footer>
        </main>
      </LocomotiveScrollProvider>
    </BrowserRouter>
  );
}

export default App;
