import { useEffect, useRef, useState, Fragment } from "react";
import { gsap, Sine } from "gsap/all";
import "./Carousel.scss";

function Carousel(props) {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [videoMuted, setVideoMuted] = useState(true);
  const carouselRef = useRef();
  const videoRef = useRef();
  const slides = props.slides;
  const autoplay = props.autoplay || false;
  const autoplayInteval = props.autoplayInteval || 6000;
  const height = props.height || "100vh";
  const selector = gsap.utils.selector(carouselRef);
  const animateSlide = () => {
    gsap.timeline({ defaults: { ease: Sine } }).fromTo(
      selector(".slide"),
      { opacity: 0, scale: 1.1 },
      {
        opacity: 1,
        scale: 1,
      }
    );
  };
  const caouselNext = () => {
    setCurrentSlide((prevVal) => (prevVal < slides.length - 1 ? ++prevVal : 0));
    animateSlide();
    if (autoplay) setTimeout(caouselNext, autoplayInteval);
  };
  const caouselPrev = () => {
    setCurrentSlide((prevVal) => (prevVal > 0 ? --prevVal : slides.length - 1));
    animateSlide();
  };
  const toggleSound = () => {
    videoRef.current.muted = !videoRef.current.muted;
    setVideoMuted(videoRef.current.muted);
  };
  useEffect(() => {
    if (autoplay) setTimeout(caouselNext, autoplayInteval);
  }, []);
  return (
    <div
      className={"carousel " + props.fit}
      ref={carouselRef}
      style={{ height: height }}
    >
      <div className="slide">
        {slides[currentSlide]?.isVideo ? (
          <Fragment>
            <video
              src={slides[currentSlide].video}
              ref={videoRef}
              autoPlay
              playsInline
              muted
              loop
            ></video>
            <div className="audio_control_btn" onClick={toggleSound}>
              <svg
                width="24"
                height="24"
                xmlns="http://www.w3.org/2000/svg"
                fillRule="evenodd"
                clipRule="evenodd"
                style={{ display: videoMuted ? "block" : "none" }}
              >
                <path d="M18 23l-9.305-5.998.835-.651 7.47 4.815v-10.65l1-.781v13.265zm0-15.794l5.384-4.206.616.788-23.384 18.264-.616-.788 5.46-4.264h-2.46v-10h5.691l9.309-6v6.206zm-11.26 8.794l1.26-.984v-7.016h-4v8h2.74zm10.26-8.013v-5.153l-8 5.157v6.244l8-6.248z" />
              </svg>
              <svg
                width="24"
                height="24"
                xmlns="http://www.w3.org/2000/svg"
                fillRule="evenodd"
                clipRule="evenodd"
                style={{ display: videoMuted ? "none" : "block" }}
              >
                <path d="M15 23l-9.309-6h-5.691v-10h5.691l9.309-6v22zm-9-15.009v8.018l8 5.157v-18.332l-8 5.157zm14.228-4.219c2.327 1.989 3.772 4.942 3.772 8.229 0 3.288-1.445 6.241-3.77 8.229l-.708-.708c2.136-1.791 3.478-4.501 3.478-7.522s-1.342-5.731-3.478-7.522l.706-.706zm-2.929 2.929c1.521 1.257 2.476 3.167 2.476 5.299 0 2.132-.955 4.042-2.476 5.299l-.706-.706c1.331-1.063 2.182-2.729 2.182-4.591 0-1.863-.851-3.529-2.184-4.593l.708-.708zm-12.299 1.299h-4v8h4v-8z" />
              </svg>
            </div>
          </Fragment>
        ) : slides[currentSlide]?.url ? (
          <a href={slides[currentSlide]?.url}>
            <img src={slides[currentSlide]?.image} alt="Coocaa TV" />
          </a>
        ) : (
          <img src={slides[currentSlide]?.image} alt="Coocaa TV" />
        )}
      </div>
      {slides.length > 1 ? (
        <div className="carousel_nav">
          <div className="nav left" onClick={caouselPrev}>
            <svg
              width="54"
              height="54"
              viewBox="0 0 54 54"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M27 4.5C39.4294 4.5 49.5 14.5706 49.5 27C49.5 39.4294 39.4294 49.5 27 49.5C14.5706 49.5 4.5 39.4294 4.5 27C4.5 14.5706 14.5706 4.5 27 4.5ZM24.378 17.5282L31.2278 24.0968H14.6613C13.4546 24.0968 12.4839 25.0675 12.4839 26.2742V27.7258C12.4839 28.9325 13.4546 29.9032 14.6613 29.9032H31.2278L24.378 36.4718C23.498 37.3155 23.4798 38.7218 24.3417 39.5837L25.3397 40.5726C26.1925 41.4254 27.5716 41.4254 28.4153 40.5726L40.4546 28.5423C41.3075 27.6895 41.3075 26.3105 40.4546 25.4667L28.4153 13.4183C27.5625 12.5655 26.1835 12.5655 25.3397 13.4183L24.3417 14.4073C23.4798 15.2782 23.498 16.6845 24.378 17.5282Z"
                fill="white"
              />
            </svg>
          </div>
          <div className="nav right" onClick={caouselNext}>
            <svg
              width="54"
              height="54"
              viewBox="0 0 54 54"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M27 4.5C39.4294 4.5 49.5 14.5706 49.5 27C49.5 39.4294 39.4294 49.5 27 49.5C14.5706 49.5 4.5 39.4294 4.5 27C4.5 14.5706 14.5706 4.5 27 4.5ZM24.378 17.5282L31.2278 24.0968H14.6613C13.4546 24.0968 12.4839 25.0675 12.4839 26.2742V27.7258C12.4839 28.9325 13.4546 29.9032 14.6613 29.9032H31.2278L24.378 36.4718C23.498 37.3155 23.4798 38.7218 24.3417 39.5837L25.3397 40.5726C26.1925 41.4254 27.5716 41.4254 28.4153 40.5726L40.4546 28.5423C41.3075 27.6895 41.3075 26.3105 40.4546 25.4667L28.4153 13.4183C27.5625 12.5655 26.1835 12.5655 25.3397 13.4183L24.3417 14.4073C23.4798 15.2782 23.498 16.6845 24.378 17.5282Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default Carousel;
